import * as moment from 'moment';

/* tslint:disable:max-line-length */
export const finance = {
    accountBalance    : {
        growRate: 38.33,
        ami     : 45332,
        series  : [
            {
                name: 'Industri',
                data: [
                    {
                        x: moment().subtract(1, 'days').day(1).toDate(),
                        y: 38.20
                    },
                    {
                        x: moment().subtract(1, 'days').day(2).toDate(),
                        y: 39.68
                    },
                    {
                        x: moment().subtract(1, 'days').day(3).toDate(),
                        y: 41.02
                    },
                    {
                        x: moment().subtract(1, 'days').day(4).toDate(),
                        y: 39.41
                    },
                    {
                        x: moment().subtract(1, 'days').day(5).toDate(),
                        y: 35.66
                    },
                    {
                        x: moment().subtract(1, 'days').day(6).toDate(),
                        y: 38.53
                    },
                    {
                        x: moment().subtract(1, 'days').day(7).toDate(),
                        y: 38.53
                    },
                    {
                        x: moment().subtract(1, 'days').day(8).toDate(),
                        y: 40.69
                    },
                    {
                        x: moment().subtract(1, 'days').day(9).toDate(),
                        y: 38.79
                    },
                ]
            },
            {
                name: 'Domestik',
                data: [
                    {
                        x: moment().subtract(1, 'days').day(1).toDate(),
                        y: 17.78
                    },
                    {
                        x: moment().subtract(1, 'days').day(2).toDate(),
                        y: 20.15
                    },
                    {
                        x: moment().subtract(1, 'days').day(3).toDate(),
                        y: 18.92
                    },
                    {
                        x: moment().subtract(1, 'days').day(4).toDate(),
                        y: 17.08
                    },
                    {
                        x: moment().subtract(1, 'days').day(5).toDate(),
                        y: 17.1
                    },
                    {
                        x: moment().subtract(1, 'days').day(6).toDate(),
                        y: 15.70
                    },
                    {
                        x: moment().subtract(1, 'days').day(7).toDate(),
                        y: 15.07
                    },
                    {
                        x: moment().subtract(1, 'days').day(8).toDate(),
                        y: 14.51
                    },
                    {
                        x: moment().subtract(1, 'days').day(9).toDate(),
                        y: 15.22
                    }
                ]
            }
        ]
    },
    budget            : {
        expenses     : 11763.34,
        expensesLimit: 20000,
        savings      : 10974.12,
        savingsGoal  : 250000,
        bills        : 1789.22,
        billsLimit   : 1000
    },
    previousStatement : {
        status : 'paid',
        date   : moment().startOf('day').subtract(15, 'days').format('LL'),
        limit  : 34500,
        spent  : 27221.21,
        minimum: 7331.94
    },
    currentStatement  : {
        status : 'pending',
        date   : moment().startOf('day').subtract(15, 'days').add(1, 'month').format('LL'),
        limit  : 34500,
        spent  : 39819.41,
        minimum: 9112.51
    },
    recentTransactions: [
        {
            id           : '1b6fd296-bc6a-4d45-bf4f-e45519a58cf5',
            transactionId: '301005',
            name         : 'PT. Balmer Lawrie Ind',
            amount       :  121825000,
            status       : 'Industri',
            date         : '2019-10-07T22:22:37.274Z'
        },
        {
            id           : '2dec6074-98bd-4623-9526-6480e4776569',
            transactionId: '300027',
            name         : 'PT. Baja Perkasa Sentosa',
            amount       : 121775000,
            status       : 'Industri',
            date         : '2019-12-18T14:51:24.461Z'
        },
        {
            id           : 'ae7c065f-4197-4021-a799-7a221822ad1d',
            transactionId: '302025',
            name         : 'PT. Bahari Makmur Sejati',
            amount       : 121665000,
            status       : 'Industri',
            date         : '2019-12-25T17:52:14.304Z'
        },
        {
            id           : '0c43dd40-74f6-49d5-848a-57a4a45772ab',
            transactionId: '402007',
            name         : 'PT. Avia Avian',
            amount       : 120005000,
            status       : 'Industri',
            date         : '2019-11-29T06:32:16.111Z'
        },
        {
            id           : 'e5c9f0ed-a64c-4bfe-a113-29f80b4e162c',
            transactionId: '301012',
            name         : 'PT. Astrindo Lestari',
            amount       : 119665000,
            status       : 'Industri',
            date         : '2019-11-24T12:13:23.064Z'
        },
        {
            id           : '1b6fd296-bc6a-4d45-bf4f-e45519a58cf5',
            transactionId: '301005',
            name         : 'PT. Balmer Lawrie Ind',
            amount       :  121825000,
            status       : 'Industri',
            date         : '2019-10-07T22:22:37.274Z'
        },
        {
            id           : '2dec6074-98bd-4623-9526-6480e4776569',
            transactionId: '300027',
            name         : 'PT. Baja Perkasa Sentosa',
            amount       : 121775000,
            status       : 'Industri',
            date         : '2019-12-18T14:51:24.461Z'
        },
        {
            id           : 'ae7c065f-4197-4021-a799-7a221822ad1d',
            transactionId: '302025',
            name         : 'PT. Bahari Makmur Sejati',
            amount       : 121665000,
            status       : 'Industri',
            date         : '2019-12-25T17:52:14.304Z'
        },
        {
            id           : '0c43dd40-74f6-49d5-848a-57a4a45772ab',
            transactionId: '402007',
            name         : 'PT. Avia Avian',
            amount       : 120005000,
            status       : 'Industri',
            date         : '2019-11-29T06:32:16.111Z'
        },
        {
            id           : 'e5c9f0ed-a64c-4bfe-a113-29f80b4e162c',
            transactionId: '301012',
            name         : 'PT. Astrindo Lestari',
            amount       : 119665000,
            status       : 'Industri',
            date         : '2019-11-24T12:13:23.064Z'
        },
        {
            id           : '1b6fd296-bc6a-4d45-bf4f-e45519a58cf5',
            transactionId: '301005',
            name         : 'PT. Balmer Lawrie Ind',
            amount       :  121825000,
            status       : 'Industri',
            date         : '2019-10-07T22:22:37.274Z'
        },
        {
            id           : '2dec6074-98bd-4623-9526-6480e4776569',
            transactionId: '300027',
            name         : 'PT. Baja Perkasa Sentosa',
            amount       : 121775000,
            status       : 'Industri',
            date         : '2019-12-18T14:51:24.461Z'
        },
        {
            id           : 'ae7c065f-4197-4021-a799-7a221822ad1d',
            transactionId: '302025',
            name         : 'PT. Bahari Makmur Sejati',
            amount       : 121665000,
            status       : 'Industri',
            date         : '2019-12-25T17:52:14.304Z'
        },
        {
            id           : '0c43dd40-74f6-49d5-848a-57a4a45772ab',
            transactionId: '402007',
            name         : 'PT. Avia Avian',
            amount       : 120005000,
            status       : 'Industri',
            date         : '2019-11-29T06:32:16.111Z'
        },
        {
            id           : 'e5c9f0ed-a64c-4bfe-a113-29f80b4e162c',
            transactionId: '301012',
            name         : 'PT. Astrindo Lestari',
            amount       : 119665000,
            status       : 'Industri',
            date         : '2019-11-24T12:13:23.064Z'
        }
    ],
    pelangganNunggak: [
        {
            id           : '1b6fd296-bc6a-4d45-bf4f-e45519a58cf5',
            code         : '301112',
            name         : 'PT. Mitra Hasil Bumi',
            status       : 'pending',
            date         : '3 Bulan'
        },
        {
            id           : '1b6fd296-bc6a-4d45-bf4f-e45519a58cf5',
            code         : '341312',
            name         : 'PT. Malindo Feed Mill',
            status       : 'pending',
            date         : '2 Bulan 10 hari'
        },
        {
            id           : '1b6fd296-bc6a-4d45-bf4f-e45519a58cf5',
            code         : '310112',
            name         : 'PT. Dongsuh Indonesia',
            status       : 'pending',
            date         : '2 Bulan'
        },
        {
            id           : '1b6fd296-bc6a-4d45-bf4f-e45519a58cf5',
            code         : '341532',
            name         : 'PT. Century Metalindo',
            status       : 'pending',
            date         : '1 Bulan 20 hari'
        },
        {
            id           : '1b6fd296-bc6a-4d45-bf4f-e45519a58cf5',
            code         : '300920',
            name         : 'PT. Hwan Indonesia',
            status       : 'pending',
            date         : '1 bulan'
        }
    ],
    newVsReturning     : {
        uniqueVisitors: 439100,
        series        : [15, 85],
        labels        : [
            'WTP 1',
            'WTP 3'
        ]
    },
    gender             : {
        uniqueVisitors: 349100,
        series        : [18,82],
        labels        : [
            'WTP 1',
            'WTP 3'
        ]
    },
    age                : {
        uniqueVisitors: 46085,
        series        : [35, 65],
        labels        : [
            'WTP 1',
            'WTP 3'
        ]
    },
    language           : {
        uniqueVisitors: 46085,
        series        : [25, 75],
        labels        : [
            'English',
            'Other'
        ]
    },
    githubIssues      : {
        overview: {
            'this-week': {
                'new-issues'   : 214,
                'closed-issues': 75,
                'fixed'        : 3,
                'wont-fix'     : 4,
                're-opened'    : 8,
                'needs-triage' : 6
            },
            'last-week': {
                'new-issues'   : 197,
                'closed-issues': 72,
                'fixed'        : 6,
                'wont-fix'     : 11,
                're-opened'    : 6,
                'needs-triage' : 5
            }
        },
        labels  : ['Nov 20', 'Nov 21', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul','Agus','Sep','Okt','Nov','Des'],
        series  : {
            'this-week': [
                {
                    name: 'Domestik',
                    type: 'column',
                    data: [42, 28]
                },
                {
                    name: 'Industri',
                    type: 'column',
                    data: [11, 10]
                }
            ],
            'last-week': [
                {
                    name: '2021 dom',
                    type: 'column',
                    data: [21, 11, 18, 41, 28, 14, 18, 26, 53, 34, 23, 40]
                },
                {
                    name: '2021 Industri',
                    type: 'column',
                    data: [12, 15, 9, 9, 0, 10, 17, 20, 15, 24, 10, 10]
                },
                {
                    name: '2020 dom',
                    type: 'column',
                    data: [42, 28, 43, 34, 20, 25, 22, 33, 54, 12, 35, 21]
                },
                {
                    name: '2020 ind',
                    type: 'column',
                    data: [11, 10, 8, 11, 8, 10, 17, 20, 13, 24, 13, 14]
                }
            ]
        }
    },
    conversions        : {
        amount: 4123,
        labels: [
            moment().subtract(47, 'days').format('DD MMM') + ' - ' + moment().subtract(40, 'days').format('DD MMM'),
            moment().subtract(39, 'days').format('DD MMM') + ' - ' + moment().subtract(32, 'days').format('DD MMM'),
            moment().subtract(31, 'days').format('DD MMM') + ' - ' + moment().subtract(24, 'days').format('DD MMM'),
            moment().subtract(23, 'days').format('DD MMM') + ' - ' + moment().subtract(16, 'days').format('DD MMM'),
            moment().subtract(15, 'days').format('DD MMM') + ' - ' + moment().subtract(8, 'days').format('DD MMM'),
            moment().subtract(7, 'days').format('DD MMM') + ' - ' + moment().format('DD MMM')
        ],
        series: [
            {
                name: 'Conversions',
                data: [4412, 4345, 4541, 4677, 2322, 4123]
            },
            {
                name: 'Conversi2',
                data: [1412, 4145, 2541, 4677, 5322, 2123]
            }
        ]
    },
    impressions        : {
        amount: 46085,
        labels: [
            moment().subtract(31, 'days').format('DD MMM') + ' - ' + moment().subtract(24, 'days').format('DD MMM'),
            moment().subtract(23, 'days').format('DD MMM') + ' - ' + moment().subtract(16, 'days').format('DD MMM'),
            moment().subtract(15, 'days').format('DD MMM') + ' - ' + moment().subtract(8, 'days').format('DD MMM'),
            moment().subtract(7, 'days').format('DD MMM') + ' - ' + moment().format('DD MMM')
        ],
        series: [
            {
                name: 'Impressions',
                data: [11577, 11441, 11544, 11523]
            }
        ]
    },
    visits             : {
        amount: 62083,
        labels: [
            moment().subtract(31, 'days').format('DD MMM') + ' - ' + moment().subtract(24, 'days').format('DD MMM'),
            moment().subtract(23, 'days').format('DD MMM') + ' - ' + moment().subtract(16, 'days').format('DD MMM'),
            moment().subtract(15, 'days').format('DD MMM') + ' - ' + moment().subtract(8, 'days').format('DD MMM'),
            moment().subtract(7, 'days').format('DD MMM') + ' - ' + moment().format('DD MMM')
        ],
        series: [
            {
                name: 'Visits',
                data: [15521, 10519, 9522, 15521]
            },
            {
                name: 'Visits2',
                data: [5521, 8519, 3522, 7521]
            }
        ]
    },

    visitors           : {
        series: {
            'this-year': [
                {
                    name: 'Biaya Air Baku',
                    data: [
                        {
                            x: moment().subtract(12, 'months').day(1).toDate(),
                            y: 4884
                        },
                        {
                            x: moment().subtract(12, 'months').day(4).toDate(),
                            y: 5351
                        },
                        {
                            x: moment().subtract(12, 'months').day(7).toDate(),
                            y: 5293
                        },
                        {
                            x: moment().subtract(12, 'months').day(10).toDate(),
                            y: 4908
                        },
                        {
                            x: moment().subtract(12, 'months').day(13).toDate(),
                            y: 5027
                        },
                        {
                            x: moment().subtract(12, 'months').day(16).toDate(),
                            y: 4837
                        },
                        {
                            x: moment().subtract(12, 'months').day(19).toDate(),
                            y: 4484
                        },
                        {
                            x: moment().subtract(12, 'months').day(22).toDate(),
                            y: 4071
                        },
                        {
                            x: moment().subtract(12, 'months').day(25).toDate(),
                            y: 4124
                        },
                        {
                            x: moment().subtract(12, 'months').day(28).toDate(),
                            y: 4563
                        },
                        {
                            x: moment().subtract(11, 'months').day(1).toDate(),
                            y: 3820
                        },
                        {
                            x: moment().subtract(11, 'months').day(4).toDate(),
                            y: 3968
                        },
                        {
                            x: moment().subtract(11, 'months').day(7).toDate(),
                            y: 4102
                        },
                        {
                            x: moment().subtract(11, 'months').day(10).toDate(),
                            y: 3941
                        },
                        {
                            x: moment().subtract(11, 'months').day(13).toDate(),
                            y: 3566
                        },
                        {
                            x: moment().subtract(11, 'months').day(16).toDate(),
                            y: 3853
                        },
                        {
                            x: moment().subtract(11, 'months').day(19).toDate(),
                            y: 3853
                        },
                        {
                            x: moment().subtract(11, 'months').day(22).toDate(),
                            y: 4069
                        },
                        {
                            x: moment().subtract(11, 'months').day(25).toDate(),
                            y: 3879
                        },
                        {
                            x: moment().subtract(11, 'months').day(28).toDate(),
                            y: 4298
                        },
                        {
                            x: moment().subtract(10, 'months').day(1).toDate(),
                            y: 4355
                        },
                        {
                            x: moment().subtract(10, 'months').day(4).toDate(),
                            y: 4065
                        },
                        {
                            x: moment().subtract(10, 'months').day(7).toDate(),
                            y: 3650
                        },
                        {
                            x: moment().subtract(10, 'months').day(10).toDate(),
                            y: 3379
                        },
                        {
                            x: moment().subtract(10, 'months').day(13).toDate(),
                            y: 3191
                        },
                        {
                            x: moment().subtract(10, 'months').day(16).toDate(),
                            y: 2968
                        },
                        {
                            x: moment().subtract(10, 'months').day(19).toDate(),
                            y: 2957
                        },
                        {
                            x: moment().subtract(10, 'months').day(22).toDate(),
                            y: 3313
                        },
                        {
                            x: moment().subtract(10, 'months').day(25).toDate(),
                            y: 3708
                        },
                        {
                            x: moment().subtract(10, 'months').day(28).toDate(),
                            y: 3586
                        },
                        {
                            x: moment().subtract(9, 'months').day(1).toDate(),
                            y: 3965
                        },
                        {
                            x: moment().subtract(9, 'months').day(4).toDate(),
                            y: 3901
                        },
                        {
                            x: moment().subtract(9, 'months').day(7).toDate(),
                            y: 3410
                        },
                        {
                            x: moment().subtract(9, 'months').day(10).toDate(),
                            y: 3748
                        },
                        {
                            x: moment().subtract(9, 'months').day(13).toDate(),
                            y: 3929
                        },
                        {
                            x: moment().subtract(9, 'months').day(16).toDate(),
                            y: 3846
                        },
                        {
                            x: moment().subtract(9, 'months').day(19).toDate(),
                            y: 3771
                        },
                        {
                            x: moment().subtract(9, 'months').day(22).toDate(),
                            y: 4015
                        },
                        {
                            x: moment().subtract(9, 'months').day(25).toDate(),
                            y: 3589
                        },
                        {
                            x: moment().subtract(9, 'months').day(28).toDate(),
                            y: 3150
                        },
                        {
                            x: moment().subtract(8, 'months').day(1).toDate(),
                            y: 3050
                        },
                        {
                            x: moment().subtract(8, 'months').day(4).toDate(),
                            y: 2574
                        },
                        {
                            x: moment().subtract(8, 'months').day(7).toDate(),
                            y: 2823
                        },
                        {
                            x: moment().subtract(8, 'months').day(10).toDate(),
                            y: 2848
                        },
                        {
                            x: moment().subtract(8, 'months').day(13).toDate(),
                            y: 3000
                        },
                        {
                            x: moment().subtract(8, 'months').day(16).toDate(),
                            y: 3216
                        },
                        {
                            x: moment().subtract(8, 'months').day(19).toDate(),
                            y: 3299
                        },
                        {
                            x: moment().subtract(8, 'months').day(22).toDate(),
                            y: 3768
                        },
                        {
                            x: moment().subtract(8, 'months').day(25).toDate(),
                            y: 3524
                        },
                        {
                            x: moment().subtract(8, 'months').day(28).toDate(),
                            y: 3918
                        },
                        {
                            x: moment().subtract(7, 'months').day(1).toDate(),
                            y: 4145
                        },
                        {
                            x: moment().subtract(7, 'months').day(4).toDate(),
                            y: 4378
                        },
                        {
                            x: moment().subtract(7, 'months').day(7).toDate(),
                            y: 3941
                        },
                        {
                            x: moment().subtract(7, 'months').day(10).toDate(),
                            y: 3932
                        },
                        {
                            x: moment().subtract(7, 'months').day(13).toDate(),
                            y: 4380
                        },
                        {
                            x: moment().subtract(7, 'months').day(16).toDate(),
                            y: 4243
                        },
                        {
                            x: moment().subtract(7, 'months').day(19).toDate(),
                            y: 4367
                        },
                        {
                            x: moment().subtract(7, 'months').day(22).toDate(),
                            y: 3879
                        },
                        {
                            x: moment().subtract(7, 'months').day(25).toDate(),
                            y: 4357
                        },
                        {
                            x: moment().subtract(7, 'months').day(28).toDate(),
                            y: 4181
                        },
                        {
                            x: moment().subtract(6, 'months').day(1).toDate(),
                            y: 4619
                        },
                        {
                            x: moment().subtract(6, 'months').day(4).toDate(),
                            y: 4769
                        },
                        {
                            x: moment().subtract(6, 'months').day(7).toDate(),
                            y: 4901
                        },
                        {
                            x: moment().subtract(6, 'months').day(10).toDate(),
                            y: 4640
                        },
                        {
                            x: moment().subtract(6, 'months').day(13).toDate(),
                            y: 5128
                        },
                        {
                            x: moment().subtract(6, 'months').day(16).toDate(),
                            y: 5015
                        },
                        {
                            x: moment().subtract(6, 'months').day(19).toDate(),
                            y: 5360
                        },
                        {
                            x: moment().subtract(6, 'months').day(22).toDate(),
                            y: 5608
                        },
                        {
                            x: moment().subtract(6, 'months').day(25).toDate(),
                            y: 5272
                        },
                        {
                            x: moment().subtract(6, 'months').day(28).toDate(),
                            y: 5660
                        },
                        {
                            x: moment().subtract(5, 'months').day(1).toDate(),
                            y: 5836
                        },
                        {
                            x: moment().subtract(5, 'months').day(4).toDate(),
                            y: 5659
                        },
                        {
                            x: moment().subtract(5, 'months').day(7).toDate(),
                            y: 5575
                        },
                        {
                            x: moment().subtract(5, 'months').day(10).toDate(),
                            y: 5474
                        },
                        {
                            x: moment().subtract(5, 'months').day(13).toDate(),
                            y: 5427
                        },
                        {
                            x: moment().subtract(5, 'months').day(16).toDate(),
                            y: 5865
                        },
                        {
                            x: moment().subtract(5, 'months').day(19).toDate(),
                            y: 5700
                        },
                        {
                            x: moment().subtract(5, 'months').day(22).toDate(),
                            y: 6052
                        },
                        {
                            x: moment().subtract(5, 'months').day(25).toDate(),
                            y: 5760
                        },
                        {
                            x: moment().subtract(5, 'months').day(28).toDate(),
                            y: 5648
                        },
                        {
                            x: moment().subtract(4, 'months').day(1).toDate(),
                            y: 5435
                        },
                        {
                            x: moment().subtract(4, 'months').day(4).toDate(),
                            y: 5239
                        },
                        {
                            x: moment().subtract(4, 'months').day(7).toDate(),
                            y: 5452
                        },
                        {
                            x: moment().subtract(4, 'months').day(10).toDate(),
                            y: 5416
                        },
                        {
                            x: moment().subtract(4, 'months').day(13).toDate(),
                            y: 5195
                        },
                        {
                            x: moment().subtract(4, 'months').day(16).toDate(),
                            y: 5119
                        },
                        {
                            x: moment().subtract(4, 'months').day(19).toDate(),
                            y: 4635
                        },
                        {
                            x: moment().subtract(4, 'months').day(22).toDate(),
                            y: 4833
                        },
                        {
                            x: moment().subtract(4, 'months').day(25).toDate(),
                            y: 4584
                        },
                        {
                            x: moment().subtract(4, 'months').day(28).toDate(),
                            y: 4822
                        },
                        {
                            x: moment().subtract(3, 'months').day(1).toDate(),
                            y: 4582
                        },
                        {
                            x: moment().subtract(3, 'months').day(4).toDate(),
                            y: 4348
                        },
                        {
                            x: moment().subtract(3, 'months').day(7).toDate(),
                            y: 4132
                        },
                        {
                            x: moment().subtract(3, 'months').day(10).toDate(),
                            y: 4099
                        },
                        {
                            x: moment().subtract(3, 'months').day(13).toDate(),
                            y: 3849
                        },
                        {
                            x: moment().subtract(3, 'months').day(16).toDate(),
                            y: 4010
                        },
                        {
                            x: moment().subtract(3, 'months').day(19).toDate(),
                            y: 4486
                        },
                        {
                            x: moment().subtract(3, 'months').day(22).toDate(),
                            y: 4403
                        },
                        {
                            x: moment().subtract(3, 'months').day(25).toDate(),
                            y: 4141
                        },
                        {
                            x: moment().subtract(3, 'months').day(28).toDate(),
                            y: 3780
                        },
                        {
                            x: moment().subtract(2, 'months').day(1).toDate(),
                            y: 3524
                        },
                        {
                            x: moment().subtract(2, 'months').day(4).toDate(),
                            y: 3212
                        },
                        {
                            x: moment().subtract(2, 'months').day(7).toDate(),
                            y: 3568
                        },
                        {
                            x: moment().subtract(2, 'months').day(10).toDate(),
                            y: 3800
                        },
                        {
                            x: moment().subtract(2, 'months').day(13).toDate(),
                            y: 3796
                        },
                        {
                            x: moment().subtract(2, 'months').day(16).toDate(),
                            y: 3870
                        },
                        {
                            x: moment().subtract(2, 'months').day(19).toDate(),
                            y: 3745
                        },
                        {
                            x: moment().subtract(2, 'months').day(22).toDate(),
                            y: 3751
                        },
                        {
                            x: moment().subtract(2, 'months').day(25).toDate(),
                            y: 3310
                        },
                        {
                            x: moment().subtract(2, 'months').day(28).toDate(),
                            y: 3509
                        },
                        {
                            x: moment().subtract(1, 'months').day(1).toDate(),
                            y: 3187
                        },
                        {
                            x: moment().subtract(1, 'months').day(4).toDate(),
                            y: 2918
                        },
                        {
                            x: moment().subtract(1, 'months').day(7).toDate(),
                            y: 3191
                        },
                        {
                            x: moment().subtract(1, 'months').day(10).toDate(),
                            y: 3437
                        },
                        {
                            x: moment().subtract(1, 'months').day(13).toDate(),
                            y: 3291
                        },
                        {
                            x: moment().subtract(1, 'months').day(16).toDate(),
                            y: 3317
                        },
                        {
                            x: moment().subtract(1, 'months').day(19).toDate(),
                            y: 3716
                        },
                        {
                            x: moment().subtract(1, 'months').day(22).toDate(),
                            y: 3260
                        },
                        {
                            x: moment().subtract(1, 'months').day(25).toDate(),
                            y: 3694
                        },
                        {
                            x: moment().subtract(1, 'months').day(28).toDate(),
                            y: 3598
                        }
                    ]
                },
                {
                    name: 'Biaya Bahan Baku',
                    data: [
                        {
                            x: moment().subtract(12, 'months').day(1).toDate(),
                            y: 2021
                        },
                        {
                            x: moment().subtract(12, 'months').day(4).toDate(),
                            y: 1749
                        },
                        {
                            x: moment().subtract(12, 'months').day(7).toDate(),
                            y: 1654
                        },
                        {
                            x: moment().subtract(12, 'months').day(10).toDate(),
                            y: 1900
                        },
                        {
                            x: moment().subtract(12, 'months').day(13).toDate(),
                            y: 1647
                        },
                        {
                            x: moment().subtract(12, 'months').day(16).toDate(),
                            y: 1315
                        },
                        {
                            x: moment().subtract(12, 'months').day(19).toDate(),
                            y: 1807
                        },
                        {
                            x: moment().subtract(12, 'months').day(22).toDate(),
                            y: 1793
                        },
                        {
                            x: moment().subtract(12, 'months').day(25).toDate(),
                            y: 1892
                        },
                        {
                            x: moment().subtract(12, 'months').day(28).toDate(),
                            y: 1846
                        },
                        {
                            x: moment().subtract(11, 'months').day(1).toDate(),
                            y: 1804
                        },
                        {
                            x: moment().subtract(11, 'months').day(4).toDate(),
                            y: 1778
                        },
                        {
                            x: moment().subtract(11, 'months').day(7).toDate(),
                            y: 2015
                        },
                        {
                            x: moment().subtract(11, 'months').day(10).toDate(),
                            y: 1892
                        },
                        {
                            x: moment().subtract(11, 'months').day(13).toDate(),
                            y: 1708
                        },
                        {
                            x: moment().subtract(11, 'months').day(16).toDate(),
                            y: 1711
                        },
                        {
                            x: moment().subtract(11, 'months').day(19).toDate(),
                            y: 1570
                        },
                        {
                            x: moment().subtract(11, 'months').day(22).toDate(),
                            y: 1507
                        },
                        {
                            x: moment().subtract(11, 'months').day(25).toDate(),
                            y: 1451
                        },
                        {
                            x: moment().subtract(11, 'months').day(28).toDate(),
                            y: 1522
                        },
                        {
                            x: moment().subtract(10, 'months').day(1).toDate(),
                            y: 1977
                        },
                        {
                            x: moment().subtract(10, 'months').day(4).toDate(),
                            y: 2367
                        },
                        {
                            x: moment().subtract(10, 'months').day(7).toDate(),
                            y: 2798
                        },
                        {
                            x: moment().subtract(10, 'months').day(10).toDate(),
                            y: 3080
                        },
                        {
                            x: moment().subtract(10, 'months').day(13).toDate(),
                            y: 2856
                        },
                        {
                            x: moment().subtract(10, 'months').day(16).toDate(),
                            y: 2745
                        },
                        {
                            x: moment().subtract(10, 'months').day(19).toDate(),
                            y: 2750
                        },
                        {
                            x: moment().subtract(10, 'months').day(22).toDate(),
                            y: 2728
                        },
                        {
                            x: moment().subtract(10, 'months').day(25).toDate(),
                            y: 2436
                        },
                        {
                            x: moment().subtract(10, 'months').day(28).toDate(),
                            y: 2289
                        },
                        {
                            x: moment().subtract(9, 'months').day(1).toDate(),
                            y: 2804
                        },
                        {
                            x: moment().subtract(9, 'months').day(4).toDate(),
                            y: 2777
                        },
                        {
                            x: moment().subtract(9, 'months').day(7).toDate(),
                            y: 3024
                        },
                        {
                            x: moment().subtract(9, 'months').day(10).toDate(),
                            y: 2657
                        },
                        {
                            x: moment().subtract(9, 'months').day(13).toDate(),
                            y: 2218
                        },
                        {
                            x: moment().subtract(9, 'months').day(16).toDate(),
                            y: 1964
                        },
                        {
                            x: moment().subtract(9, 'months').day(19).toDate(),
                            y: 1674
                        },
                        {
                            x: moment().subtract(9, 'months').day(22).toDate(),
                            y: 1721
                        },
                        {
                            x: moment().subtract(9, 'months').day(25).toDate(),
                            y: 2005
                        },
                        {
                            x: moment().subtract(9, 'months').day(28).toDate(),
                            y: 1613
                        },
                        {
                            x: moment().subtract(8, 'months').day(1).toDate(),
                            y: 1071
                        },
                        {
                            x: moment().subtract(8, 'months').day(4).toDate(),
                            y: 1079
                        },
                        {
                            x: moment().subtract(8, 'months').day(7).toDate(),
                            y: 1133
                        },
                        {
                            x: moment().subtract(8, 'months').day(10).toDate(),
                            y: 1536
                        },
                        {
                            x: moment().subtract(8, 'months').day(13).toDate(),
                            y: 2016
                        },
                        {
                            x: moment().subtract(8, 'months').day(16).toDate(),
                            y: 2256
                        },
                        {
                            x: moment().subtract(8, 'months').day(19).toDate(),
                            y: 1934
                        },
                        {
                            x: moment().subtract(8, 'months').day(22).toDate(),
                            y: 1832
                        },
                        {
                            x: moment().subtract(8, 'months').day(25).toDate(),
                            y: 2075
                        },
                        {
                            x: moment().subtract(8, 'months').day(28).toDate(),
                            y: 1709
                        },
                        {
                            x: moment().subtract(7, 'months').day(1).toDate(),
                            y: 1831
                        },
                        {
                            x: moment().subtract(7, 'months').day(4).toDate(),
                            y: 1434
                        },
                        {
                            x: moment().subtract(7, 'months').day(7).toDate(),
                            y: 1293
                        },
                        {
                            x: moment().subtract(7, 'months').day(10).toDate(),
                            y: 1064
                        },
                        {
                            x: moment().subtract(7, 'months').day(13).toDate(),
                            y: 1080
                        },
                        {
                            x: moment().subtract(7, 'months').day(16).toDate(),
                            y: 1032
                        },
                        {
                            x: moment().subtract(7, 'months').day(19).toDate(),
                            y: 1280
                        },
                        {
                            x: moment().subtract(7, 'months').day(22).toDate(),
                            y: 1344
                        },
                        {
                            x: moment().subtract(7, 'months').day(25).toDate(),
                            y: 1835
                        },
                        {
                            x: moment().subtract(7, 'months').day(28).toDate(),
                            y: 2287
                        },
                        {
                            x: moment().subtract(6, 'months').day(1).toDate(),
                            y: 2692
                        },
                        {
                            x: moment().subtract(6, 'months').day(4).toDate(),
                            y: 2250
                        },
                        {
                            x: moment().subtract(6, 'months').day(7).toDate(),
                            y: 1814
                        },
                        {
                            x: moment().subtract(6, 'months').day(10).toDate(),
                            y: 1906
                        },
                        {
                            x: moment().subtract(6, 'months').day(13).toDate(),
                            y: 1973
                        },
                        {
                            x: moment().subtract(6, 'months').day(16).toDate(),
                            y: 1882
                        },
                        {
                            x: moment().subtract(6, 'months').day(19).toDate(),
                            y: 2333
                        },
                        {
                            x: moment().subtract(6, 'months').day(22).toDate(),
                            y: 2048
                        },
                        {
                            x: moment().subtract(6, 'months').day(25).toDate(),
                            y: 2547
                        },
                        {
                            x: moment().subtract(6, 'months').day(28).toDate(),
                            y: 2884
                        },
                        {
                            x: moment().subtract(5, 'months').day(1).toDate(),
                            y: 2771
                        },
                        {
                            x: moment().subtract(5, 'months').day(4).toDate(),
                            y: 2522
                        },
                        {
                            x: moment().subtract(5, 'months').day(7).toDate(),
                            y: 2543
                        },
                        {
                            x: moment().subtract(5, 'months').day(10).toDate(),
                            y: 2413
                        },
                        {
                            x: moment().subtract(5, 'months').day(13).toDate(),
                            y: 2002
                        },
                        {
                            x: moment().subtract(5, 'months').day(16).toDate(),
                            y: 1838
                        },
                        {
                            x: moment().subtract(5, 'months').day(19).toDate(),
                            y: 1830
                        },
                        {
                            x: moment().subtract(5, 'months').day(22).toDate(),
                            y: 1872
                        },
                        {
                            x: moment().subtract(5, 'months').day(25).toDate(),
                            y: 2246
                        },
                        {
                            x: moment().subtract(5, 'months').day(28).toDate(),
                            y: 2171
                        },
                        {
                            x: moment().subtract(4, 'months').day(1).toDate(),
                            y: 2988
                        },
                        {
                            x: moment().subtract(4, 'months').day(4).toDate(),
                            y: 2694
                        },
                        {
                            x: moment().subtract(4, 'months').day(7).toDate(),
                            y: 2806
                        },
                        {
                            x: moment().subtract(4, 'months').day(10).toDate(),
                            y: 3040
                        },
                        {
                            x: moment().subtract(4, 'months').day(13).toDate(),
                            y: 2898
                        },
                        {
                            x: moment().subtract(4, 'months').day(16).toDate(),
                            y: 3013
                        },
                        {
                            x: moment().subtract(4, 'months').day(19).toDate(),
                            y: 2760
                        },
                        {
                            x: moment().subtract(4, 'months').day(22).toDate(),
                            y: 3021
                        },
                        {
                            x: moment().subtract(4, 'months').day(25).toDate(),
                            y: 2688
                        },
                        {
                            x: moment().subtract(4, 'months').day(28).toDate(),
                            y: 2572
                        },
                        {
                            x: moment().subtract(3, 'months').day(1).toDate(),
                            y: 2789
                        },
                        {
                            x: moment().subtract(3, 'months').day(4).toDate(),
                            y: 3069
                        },
                        {
                            x: moment().subtract(3, 'months').day(7).toDate(),
                            y: 3142
                        },
                        {
                            x: moment().subtract(3, 'months').day(10).toDate(),
                            y: 3614
                        },
                        {
                            x: moment().subtract(3, 'months').day(13).toDate(),
                            y: 3202
                        },
                        {
                            x: moment().subtract(3, 'months').day(16).toDate(),
                            y: 2730
                        },
                        {
                            x: moment().subtract(3, 'months').day(19).toDate(),
                            y: 2951
                        },
                        {
                            x: moment().subtract(3, 'months').day(22).toDate(),
                            y: 3267
                        },
                        {
                            x: moment().subtract(3, 'months').day(25).toDate(),
                            y: 2882
                        },
                        {
                            x: moment().subtract(3, 'months').day(28).toDate(),
                            y: 2885
                        },
                        {
                            x: moment().subtract(2, 'months').day(1).toDate(),
                            y: 2915
                        },
                        {
                            x: moment().subtract(2, 'months').day(4).toDate(),
                            y: 2790
                        },
                        {
                            x: moment().subtract(2, 'months').day(7).toDate(),
                            y: 3071
                        },
                        {
                            x: moment().subtract(2, 'months').day(10).toDate(),
                            y: 2802
                        },
                        {
                            x: moment().subtract(2, 'months').day(13).toDate(),
                            y: 2382
                        },
                        {
                            x: moment().subtract(2, 'months').day(16).toDate(),
                            y: 1883
                        },
                        {
                            x: moment().subtract(2, 'months').day(19).toDate(),
                            y: 1448
                        },
                        {
                            x: moment().subtract(2, 'months').day(22).toDate(),
                            y: 1176
                        },
                        {
                            x: moment().subtract(2, 'months').day(25).toDate(),
                            y: 1275
                        },
                        {
                            x: moment().subtract(2, 'months').day(28).toDate(),
                            y: 1136
                        },
                        {
                            x: moment().subtract(1, 'months').day(1).toDate(),
                            y: 1160
                        },
                        {
                            x: moment().subtract(1, 'months').day(4).toDate(),
                            y: 1524
                        },
                        {
                            x: moment().subtract(1, 'months').day(7).toDate(),
                            y: 1305
                        },
                        {
                            x: moment().subtract(1, 'months').day(10).toDate(),
                            y: 1725
                        },
                        {
                            x: moment().subtract(1, 'months').day(13).toDate(),
                            y: 1850
                        },
                        {
                            x: moment().subtract(1, 'months').day(16).toDate(),
                            y: 2304
                        },
                        {
                            x: moment().subtract(1, 'months').day(19).toDate(),
                            y: 2187
                        },
                        {
                            x: moment().subtract(1, 'months').day(22).toDate(),
                            y: 2597
                        },
                        {
                            x: moment().subtract(1, 'months').day(25).toDate(),
                            y: 2246
                        },
                        {
                            x: moment().subtract(1, 'months').day(28).toDate(),
                            y: 1767
                        }
                    ]
                }
            ]
        }
    },
};
