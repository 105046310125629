<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-blue-900 text-white print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.futuristic"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationHeader>
        <!-- Logo -->
        <div class="flex items-center h-20 p-6 pb-0">
            <img
                class="w-10"
                src="assets/images/logo/logo.svg">

            <div *ngIf="!forOxi" class="ml-4">
                <div class="font-extrabold">PT SCTK</div>
                <div class="text-sm">Powered By Aguadigi</div>
            </div>
            <div *ngIf="forOxi" class="ml-4">
                <div class="font-extrabold">Oxidigi</div>
                <div class="text-sm">Solution Platform For Sustainability</div>
            </div>
        </div>
    </ng-container>
    <!-- Navigation footer hook -->
    
    <ng-container fuseVerticalNavigationFooter>
        <div class="flex items-center w-full px-6 py-2 border-t">
            <button
                class="flex items-center ml-7 space-x-1 sm:space-x-2"
                mat-icon-button
                (click)="signOut()">
                <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
                <div class="ml-3">Keluar</div>
            </button>
        </div>
    </ng-container>
    
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden sticky top-0">
        <!-- Navigation toggle button -->
        <button
            class="mr-2"
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <!--
        <button
            class="flex items-center pl-2 ml-auto space-x-1 sm:space-x-2"
            mat-icon-button
            (click)="signOut()">
            <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        </button>
        -->
        <div class="flex items-center pl-2 ml-auto space-x-1 sm:space-x-2">
            <notifications></notifications>            
        </div>
        
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <fuse-alert
            *ngIf="internetStatus===onlineStatusCheck.OFFLINE"
            class="mx-5 my-3"
            [type]="'error'"
            [appearance]="'outline'">
            <span fuseAlertTitle>Koneksi Internet Terputus</span>
            System tidak bisa memperbarui data. Silahkan periksa koneksi anda.
        </fuse-alert>
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    
    <div class="relative flex flex-0 items-center justify-between w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary" (click)="signOut()">{{user.name}}</span>
        <div class="flex items-stretch" *ngIf="!forOxi">
            <div class="text-sm text-secondary pr-2 pt-1">Powered&nbsp;By</div>
            <img
                class="h-7"
                src="assets/images/logo/aguadigi_horizontal.png">
        </div>
    </div>
    
</div>
