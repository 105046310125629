/* eslint-disable */
import { FuseNavigationItem } from "@fuse/components/navigation";

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: "dashboards",
    title: "Dashboards",
    subtitle: "Unique dashboard designs",
    type: "group",
    icon: "heroicons_outline:home",
    children: [
      {
        id: "dashboards.maindash",
        title: "Dashboard",
        type: "basic",
        icon: "heroicons_outline:chart-pie",
        link: "/dashboards/maindash",
      },
      {
        id: "dashboards.analytics",
        title: "Harian",
        type: "basic",
        icon: "heroicons_outline:chart-square-bar",
        link: "/dashboards/analytics",
      },
      {
        id: "dashboards.project",
        title: "Teknisi (RM)",
        type: "basic",
        icon: "heroicons_outline:clipboard-check",
        link: "/dashboards/project",
      },
    ],
  },
  {
    id: "apps",
    title: "Applications",
    subtitle: "Custom made application designs",
    type: "group",
    icon: "heroicons_outline:home",
    children: [
      {
        id: "apps.karyawan",
        title: "Karyawan",
        type: "basic",
        icon: "heroicons_outline:user-group",
        link: "/apps/karyawan",
      },

      {
        id: "apps.jadwal",
        title: "Jadwal",
        type: "basic",
        icon: "heroicons_outline:calendar",
        link: "/apps/jadwal",
      },
      {
        id: "apps.change",
        title: "Ganti Shift",
        type: "basic",
        icon: "heroicons_outline:switch-horizontal",
        link: "/apps/absensi/change",
        exactMatch: true,
      },
      {
        id: "apps.temuan",
        title: "Temuan Lap",
        type: "basic",
        icon: "heroicons_outline:flag",
        link: "/apps/temuan",
      },
      {
        id: "apps.tugas",
        title: "Kegiatan",
        type: "collapsable",
        icon: "heroicons_outline:clipboard-list",
        children: [
          {
            id: "apps.tugas.tugas",
            title: "Rutin",
            type: "basic",
            link: "/apps/tugas",
            exactMatch: true,
          },
          {
            id: "apps.tugas.urutan",
            title: "Urutan",
            type: "basic",
            link: "/apps/tugas/sort",
            exactMatch: true,
          },
          {
            id: "apps.tugas.nonrutin",
            title: "Non Rutin",
            type: "basic",
            link: "/apps/nonrutin",
          },
        ],
      },
      {
        id: "apps.customer",
        title: "Pelanggan",
        type: "collapsable",
        icon: "heroicons_outline:office-building",
        children: [
          {
            id: "apps.customer.list",
            title: "Daftar",
            type: "basic",
            link: "/apps/customer",
            exactMatch: true,
          },
          {
            id: "apps.customer.reset",
            title: "Pergantian Meteran",
            type: "basic",
            link: "/apps/customer/reset",
            exactMatch: true,
          },
        ],
      },
      /* {
                title   : 'Pelanggan',
                type    : 'collapsable',
                icon    : 'heroicons_outline:clipboard-list',
                children: [
                    {
                        id   : 'apps.customer',
                        title: 'Aktif',
                        type : 'basic',
                        link : '/apps/customer'
                    },
                    {
                        id   : 'apps.customer',
                        title: 'Pengajuan',
                        type : 'basic',
                        link : '/apps/customer/pengajuan'
                    }
                ]
            },     */
      /* {
                title   : 'Alat',
                type    : 'collapsable',
                icon    : 'heroicons_outline:cube-transparent',
                children: [
                    {
                        id   : 'apps.sparepart-category',
                        title: 'Kategori',
                        type : 'basic',
                        link : '/apps/sparepart-category'
                    },
                    {
                        id   : 'apps.sparepart',
                        title: 'Spare Part',
                        type : 'basic',
                        link : '/apps/sparepart'
                    }
                ]
            }, */

      /* {
                id      : 'apps.calendar',
                title   : 'Calendar',
                type    : 'basic',
                icon    : 'heroicons_outline:calendar',
                link    : '/apps/calendar'
            }, */
      {
        id: "apps.hse",
        title: "HSE",
        type: "basic",
        icon: "heroicons_outline:lightning-bolt",
        link: "/apps/hse",
      },
      {
        id: "apps.lab",
        title: "Laboratorium",
        type: "collapsable",
        icon: "heroicons_outline:beaker",
        children: [
          {
            id: "apps.lab.kimia",
            title: "Pengujian",
            type: "basic",
            link: "/apps/jartest",
            exactMatch: true,
          },
          {
            id: "apps.lab.report",
            title: "Report Pengujian",
            type: "basic",
            link: "/apps/jartest/report",
            exactMatch: true,
          },
          {
            id: "apps.lab.jartest",
            title: "Report Jartest",
            type: "basic",
            link: "/apps/jartest/jartest",
            exactMatch: true,
          },
        ],
      },
      {
        id: "apps.announcements",
        title: "Announcement",
        type: "basic",
        link: "/apps/announcements",
        icon: "heroicons_outline:speakerphone",
        exactMatch: true,
      },
      {
        id: "apps.field-finding-categories",
        title: "Field Finding Categories",
        type: "basic",
        link: "/apps/field-finding-categories",
        icon: "heroicons_outline:chart-pie",
        exactMatch: true,
      },
      {
        id: "apps.monthly-reports",
        title: "Laporan Bulanan",
        type: "basic",
        link: "/apps/monthly-reports",
        icon: "heroicons_outline:document-report",
        exactMatch: true,
      },
      {
        id: "apps.custapp",
        title: "Customer App",
        type: "basic",
        icon: "heroicons_outline:device-mobile",
        link: "/apps/custapp",
      },
    ],
  },
  {
    id: "reports",
    title: "Report",
    subtitle: "Custom made page designs",
    type: "group",
    icon: "heroicons_outline:document",
    children: [
      {
        id: "report.kegiatan",
        title: "Kegiatan Operator",
        type: "collapsable",
        icon: "heroicons_outline:document-text",
        children: [
          {
            id: "report.kegiatan.intake",
            title: "Intake",
            type: "basic",
            link: "/reports/aktivitas/intake",
            exactMatch: true,
          },
          {
            id: "report.kegiatan.wtp1",
            title: "WTP 1",
            type: "basic",
            link: "/reports/aktivitas/wtp1",
            exactMatch: true,
          },
          {
            id: "report.kegiatan.wtp3",
            title: "WTP 3",
            type: "basic",
            link: "/reports/aktivitas/wtp3",
            exactMatch: true,
          },
          {
            id: "report.kegiatan.distribusi",
            title: "Distribusi",
            type: "basic",
            link: "/reports/aktivitas/distribusi",
            exactMatch: true,
          },
          {
            id: "report.kegiatan.pelanggan",
            title: "Pelanggan",
            type: "basic",
            link: "/reports/sales/aktpel",
            exactMatch: true,
          },
        ],
      },
      {
        id: "report.teknisi",
        title: "Kegiatan Teknisi",
        type: "collapsable",
        icon: "heroicons_outline:document-remove",
        children: [
          {
            id: "report.teknisi.intake-me",
            title: "Intake",
            type: "basic",
            link: "/reports/aktivitas/intake-me",
            exactMatch: true,
          },
          {
            id: "report.teknisi.wtp1-me",
            title: "WTP 1",
            type: "basic",
            link: "/reports/aktivitas/wtp1-me",
            exactMatch: true,
          },
          {
            id: "report.teknisi.wtp2-me",
            title: "WTP 2",
            type: "basic",
            link: "/reports/aktivitas/wtp2-me",
            exactMatch: true,
          },
          {
            id: "report.teknisi.wtp3-me",
            title: "WTP 3",
            type: "basic",
            link: "/reports/aktivitas/wtp3-me",
            exactMatch: true,
          },
          {
            id: "report.teknisi.distribusi-me",
            title: "Jaringan Distribusi",
            type: "basic",
            link: "/reports/aktivitas/distribusi-me",
            exactMatch: true,
          },
          {
            id: "report.teknisi.booster-me",
            title: "Booster Pump",
            type: "basic",
            link: "/reports/aktivitas/booster-me",
            exactMatch: true,
          },
          {
            id: "report.teknisi.lain-me",
            title: "Lain Lain",
            type: "basic",
            link: "/reports/aktivitas/lain-me",
            exactMatch: true,
          },
        ],
      },
      {
        id: "report.kompilasi",
        title: "Produksi WTP",
        type: "collapsable",
        icon: "heroicons_outline:book-open",
        children: [
          {
            id: "report.kompilasi.wtp1",
            title: "WTP 1",
            type: "basic",
            link: "/reports/kompilasi-produksi/wtp1",
            exactMatch: true,
          },
          /*                     {
                        id   : 'kompilasi.wtp2',
                        title: 'WTP 2',
                        type : 'basic',
                        link : '/reports/kompilasi-produksi/wtp2',
                        exactMatch: true
                    },
 */ {
            id: "report.kompilasi.wtp3",
            title: "WTP 3",
            type: "basic",
            link: "/reports/kompilasi-produksi/wtp3",
            exactMatch: true,
          },
        ],
      },
      {
        id: "report.penjualan",
        title: "Penjualan Industri",
        type: "collapsable",
        icon: "heroicons_outline:newspaper",
        children: [
          {
            id: "report.penjualan.meter",
            title: "Angka Meter",
            type: "basic",
            link: "/reports/sales/meter",
            exactMatch: true,
          },
          {
            id: "report.penjualan.m3",
            title: "Pemakaian M3",
            type: "basic",
            link: "/reports/sales/m3",
            exactMatch: true,
          },
          {
            id: "report.penjualan.sales",
            title: "Rupiah",
            type: "basic",
            link: "/reports/sales/rp",
            exactMatch: true,
          },
        ],
      },
      {
        id: "report.billing",
        title: "Tagihan",
        type: "collapsable",
        icon: "heroicons_outline:cash",
        children: [
          {
            id: "report.billing.pelanggan",
            title: "Industri / Pelanggan",
            type: "basic",
            link: "/reports/sales/detail",
            exactMatch: true,
          },
          {
            id: "reports.billing.domestik",
            title: "Domestik",
            type: "basic",
            link: "/reports/billing/domestik",
            exactMatch: true,
          },
          {
            id: "reports.billing.industri",
            title: "Kab Serang",
            type: "basic",
            link: "/reports/billing/industri",
            exactMatch: true,
          },
        ],
      },
      // {
      //     id      : 'report.nrw',
      //     title   : 'Kehilangan Air',
      //     type    : 'basic',
      //     icon    : 'heroicons_outline:puzzle',
      //     link    : '/reports/nrw',
      // },
      {
        id: "report.teknisi.pihak3",
        title: "Pihak ke 3",
        type: "basic",
        link: "/reports/aktivitas/pihak3",
        icon: "heroicons_outline:support",
        exactMatch: true,
      },
      {
        id: "apps.absensi",
        title: "Absensi",
        type: "basic",
        icon: "heroicons_outline:view-boards",
        link: "/apps/absensi",
        exactMatch: true,
      },
      {
        id: "report.rating",
        title: "Rating Operator",
        type: "basic",
        icon: "heroicons_outline:sparkles",
        link: "/reports/rating",
      },
      /* {
                title   : 'Summary',
                type    : 'collapsable',
                icon    : 'heroicons_outline:document-report',
                children: [
                    {
                        id   : 'summary.produksi',
                        title: 'Produksi',
                        type : 'basic',
                        link : '/reports/summary/produksi',
                        exactMatch: true
                    },
                    {
                        id   : 'summary.sales',
                        title: 'Sales',
                        type : 'basic',
                        link : '/reports/summary/sales',
                        exactMatch: true
                    }
                ]
            }, */
    ],
  },
  {
    id: "pages",
    title: "Pages",
    subtitle: "Custom made page designs",
    type: "group",
    icon: "heroicons_outline:document",
    children: [
      {
        id: "pages.settings",
        title: "Settings",
        type: "basic",
        icon: "heroicons_outline:cog",
        link: "/pages/settings",
      },
      /* {
                id   : 'pages.activities',
                title: 'Activities',
                type : 'basic',
                icon : 'heroicons_outline:menu-alt-2',
                link : '/pages/activities'
            },
            {
                id      : 'pages.authentication',
                title   : 'Authentication',
                type    : 'collapsable',
                icon    : 'heroicons_outline:lock-closed',
                children: [
                    {
                        id      : 'pages.authentication.sign-in',
                        title   : 'Sign in',
                        type    : 'collapsable',
                        children: [
                            {
                                id   : 'pages.authentication.sign-in.classic',
                                title: 'Classic',
                                type : 'basic',
                                link : '/pages/authentication/sign-in/classic'
                            },
                            {
                                id   : 'pages.authentication.sign-in.modern',
                                title: 'Modern',
                                type : 'basic',
                                link : '/pages/authentication/sign-in/modern'
                            },
                            {
                                id   : 'pages.authentication.sign-in.modern-reversed',
                                title: 'Modern Reversed',
                                type : 'basic',
                                link : '/pages/authentication/sign-in/modern-reversed'
                            },
                            {
                                id   : 'pages.authentication.sign-in.split-screen',
                                title: 'Split Screen',
                                type : 'basic',
                                link : '/pages/authentication/sign-in/split-screen'
                            },
                            {
                                id   : 'pages.authentication.sign-in.split-screen-reversed',
                                title: 'Split Screen Reversed',
                                type : 'basic',
                                link : '/pages/authentication/sign-in/split-screen-reversed'
                            },
                            {
                                id   : 'pages.authentication.sign-in.fullscreen',
                                title: 'Fullscreen',
                                type : 'basic',
                                link : '/pages/authentication/sign-in/fullscreen'
                            },
                            {
                                id   : 'pages.authentication.sign-in.fullscreen-reversed',
                                title: 'Fullscreen Reversed',
                                type : 'basic',
                                link : '/pages/authentication/sign-in/fullscreen-reversed'
                            }
                        ]
                    },
                    {
                        id      : 'pages.authentication.sign-up',
                        title   : 'Sign up',
                        type    : 'collapsable',
                        link    : '/pages/authentication/sign-up',
                        children: [
                            {
                                id   : 'pages.authentication.sign-up.classic',
                                title: 'Classic',
                                type : 'basic',
                                link : '/pages/authentication/sign-up/classic'
                            },
                            {
                                id   : 'pages.authentication.sign-up.modern',
                                title: 'Modern',
                                type : 'basic',
                                link : '/pages/authentication/sign-up/modern'
                            },
                            {
                                id   : 'pages.authentication.sign-up.modern-reversed',
                                title: 'Modern Reversed',
                                type : 'basic',
                                link : '/pages/authentication/sign-up/modern-reversed'
                            },
                            {
                                id   : 'pages.authentication.sign-up.split-screen',
                                title: 'Split Screen',
                                type : 'basic',
                                link : '/pages/authentication/sign-up/split-screen'
                            },
                            {
                                id   : 'pages.authentication.sign-up.split-screen-reversed',
                                title: 'Split Screen Reversed',
                                type : 'basic',
                                link : '/pages/authentication/sign-up/split-screen-reversed'
                            },
                            {
                                id   : 'pages.authentication.sign-up.fullscreen',
                                title: 'Fullscreen',
                                type : 'basic',
                                link : '/pages/authentication/sign-up/fullscreen'
                            },
                            {
                                id   : 'pages.authentication.sign-up.fullscreen-reversed',
                                title: 'Fullscreen Reversed',
                                type : 'basic',
                                link : '/pages/authentication/sign-up/fullscreen-reversed'
                            }
                        ]
                    },
                    {
                        id      : 'pages.authentication.sign-out',
                        title   : 'Sign out',
                        type    : 'collapsable',
                        link    : '/pages/authentication/sign-out',
                        children: [
                            {
                                id   : 'pages.authentication.sign-out.classic',
                                title: 'Classic',
                                type : 'basic',
                                link : '/pages/authentication/sign-out/classic'
                            },
                            {
                                id   : 'pages.authentication.sign-out.modern',
                                title: 'Modern',
                                type : 'basic',
                                link : '/pages/authentication/sign-out/modern'
                            },
                            {
                                id   : 'pages.authentication.sign-out.modern-reversed',
                                title: 'Modern Reversed',
                                type : 'basic',
                                link : '/pages/authentication/sign-out/modern-reversed'
                            },
                            {
                                id   : 'pages.authentication.sign-out.split-screen',
                                title: 'Split Screen',
                                type : 'basic',
                                link : '/pages/authentication/sign-out/split-screen'
                            },
                            {
                                id   : 'pages.authentication.sign-out.split-screen-reversed',
                                title: 'Split Screen Reversed',
                                type : 'basic',
                                link : '/pages/authentication/sign-out/split-screen-reversed'
                            },
                            {
                                id   : 'pages.authentication.sign-out.fullscreen',
                                title: 'Fullscreen',
                                type : 'basic',
                                link : '/pages/authentication/sign-out/fullscreen'
                            },
                            {
                                id   : 'pages.authentication.sign-out.fullscreen-reversed',
                                title: 'Fullscreen Reversed',
                                type : 'basic',
                                link : '/pages/authentication/sign-out/fullscreen-reversed'
                            }
                        ]
                    },
                    {
                        id      : 'pages.authentication.forgot-password',
                        title   : 'Forgot password',
                        type    : 'collapsable',
                        link    : '/pages/authentication/forgot-password',
                        children: [
                            {
                                id   : 'pages.authentication.forgot-password.classic',
                                title: 'Classic',
                                type : 'basic',
                                link : '/pages/authentication/forgot-password/classic'
                            },
                            {
                                id   : 'pages.authentication.forgot-password.modern',
                                title: 'Modern',
                                type : 'basic',
                                link : '/pages/authentication/forgot-password/modern'
                            },
                            {
                                id   : 'pages.authentication.forgot-password.modern-reversed',
                                title: 'Modern Reversed',
                                type : 'basic',
                                link : '/pages/authentication/forgot-password/modern-reversed'
                            },
                            {
                                id   : 'pages.authentication.forgot-password.split-screen',
                                title: 'Split Screen',
                                type : 'basic',
                                link : '/pages/authentication/forgot-password/split-screen'
                            },
                            {
                                id   : 'pages.authentication.forgot-password.split-screen-reversed',
                                title: 'Split Screen Reversed',
                                type : 'basic',
                                link : '/pages/authentication/forgot-password/split-screen-reversed'
                            },
                            {
                                id   : 'pages.authentication.forgot-password.fullscreen',
                                title: 'Fullscreen',
                                type : 'basic',
                                link : '/pages/authentication/forgot-password/fullscreen'
                            },
                            {
                                id   : 'pages.authentication.forgot-password.fullscreen-reversed',
                                title: 'Fullscreen Reversed',
                                type : 'basic',
                                link : '/pages/authentication/forgot-password/fullscreen-reversed'
                            }
                        ]
                    },
                    {
                        id      : 'pages.authentication.reset-password',
                        title   : 'Reset password',
                        type    : 'collapsable',
                        link    : '/pages/authentication/reset-password',
                        children: [
                            {
                                id   : 'pages.authentication.reset-password.classic',
                                title: 'Classic',
                                type : 'basic',
                                link : '/pages/authentication/reset-password/classic'
                            },
                            {
                                id   : 'pages.authentication.reset-password.modern',
                                title: 'Modern',
                                type : 'basic',
                                link : '/pages/authentication/reset-password/modern'
                            },
                            {
                                id   : 'pages.authentication.reset-password.modern-reversed',
                                title: 'Modern Reversed',
                                type : 'basic',
                                link : '/pages/authentication/reset-password/modern-reversed'
                            },
                            {
                                id   : 'pages.authentication.reset-password.split-screen',
                                title: 'Split Screen',
                                type : 'basic',
                                link : '/pages/authentication/reset-password/split-screen'
                            },
                            {
                                id   : 'pages.authentication.reset-password.split-screen-reversed',
                                title: 'Split Screen Reversed',
                                type : 'basic',
                                link : '/pages/authentication/reset-password/split-screen-reversed'
                            },
                            {
                                id   : 'pages.authentication.reset-password.fullscreen',
                                title: 'Fullscreen',
                                type : 'basic',
                                link : '/pages/authentication/reset-password/fullscreen'
                            },
                            {
                                id   : 'pages.authentication.reset-password.fullscreen-reversed',
                                title: 'Fullscreen Reversed',
                                type : 'basic',
                                link : '/pages/authentication/reset-password/fullscreen-reversed'
                            }
                        ]
                    },
                    {
                        id      : 'pages.authentication.unlock-session',
                        title   : 'Unlock session',
                        type    : 'collapsable',
                        link    : '/pages/authentication/unlock-session',
                        children: [
                            {
                                id   : 'pages.authentication.unlock-session.classic',
                                title: 'Classic',
                                type : 'basic',
                                link : '/pages/authentication/unlock-session/classic'
                            },
                            {
                                id   : 'pages.authentication.unlock-session.modern',
                                title: 'Modern',
                                type : 'basic',
                                link : '/pages/authentication/unlock-session/modern'
                            },
                            {
                                id   : 'pages.authentication.unlock-session.modern-reversed',
                                title: 'Modern Reversed',
                                type : 'basic',
                                link : '/pages/authentication/unlock-session/modern-reversed'
                            },
                            {
                                id   : 'pages.authentication.unlock-session.split-screen',
                                title: 'Split Screen',
                                type : 'basic',
                                link : '/pages/authentication/unlock-session/split-screen'
                            },
                            {
                                id   : 'pages.authentication.unlock-session.split-screen-reversed',
                                title: 'Split Screen Reversed',
                                type : 'basic',
                                link : '/pages/authentication/unlock-session/split-screen-reversed'
                            },
                            {
                                id   : 'pages.authentication.unlock-session.fullscreen',
                                title: 'Fullscreen',
                                type : 'basic',
                                link : '/pages/authentication/unlock-session/fullscreen'
                            },
                            {
                                id   : 'pages.authentication.unlock-session.fullscreen-reversed',
                                title: 'Fullscreen Reversed',
                                type : 'basic',
                                link : '/pages/authentication/unlock-session/fullscreen-reversed'
                            }
                        ]
                    },
                    {
                        id      : 'pages.authentication.confirmation-required',
                        title   : 'Confirmation required',
                        type    : 'collapsable',
                        link    : '/pages/authentication/confirmation-required',
                        children: [
                            {
                                id   : 'pages.authentication.confirmation-required.classic',
                                title: 'Classic',
                                type : 'basic',
                                link : '/pages/authentication/confirmation-required/classic'
                            },
                            {
                                id   : 'pages.authentication.confirmation-required.modern',
                                title: 'Modern',
                                type : 'basic',
                                link : '/pages/authentication/confirmation-required/modern'
                            },
                            {
                                id   : 'pages.authentication.confirmation-required.modern-reversed',
                                title: 'Modern Reversed',
                                type : 'basic',
                                link : '/pages/authentication/confirmation-required/modern-reversed'
                            },
                            {
                                id   : 'pages.authentication.confirmation-required.split-screen',
                                title: 'Split Screen',
                                type : 'basic',
                                link : '/pages/authentication/confirmation-required/split-screen'
                            },
                            {
                                id   : 'pages.authentication.confirmation-required.split-screen-reversed',
                                title: 'Split Screen Reversed',
                                type : 'basic',
                                link : '/pages/authentication/confirmation-required/split-screen-reversed'
                            },
                            {
                                id   : 'pages.authentication.confirmation-required.fullscreen',
                                title: 'Fullscreen',
                                type : 'basic',
                                link : '/pages/authentication/confirmation-required/fullscreen'
                            },
                            {
                                id   : 'pages.authentication.confirmation-required.fullscreen-reversed',
                                title: 'Fullscreen Reversed',
                                type : 'basic',
                                link : '/pages/authentication/confirmation-required/fullscreen-reversed'
                            }
                        ]
                    }
                ]
            },
            {
                id      : 'pages.coming-soon',
                title   : 'Coming Soon',
                type    : 'collapsable',
                icon    : 'heroicons_outline:clock',
                link    : '/pages/coming-soon',
                children: [
                    {
                        id   : 'pages.coming-soon.classic',
                        title: 'Classic',
                        type : 'basic',
                        link : '/pages/coming-soon/classic'
                    },
                    {
                        id   : 'pages.coming-soon.modern',
                        title: 'Modern',
                        type : 'basic',
                        link : '/pages/coming-soon/modern'
                    },
                    {
                        id   : 'pages.coming-soon.modern-reversed',
                        title: 'Modern Reversed',
                        type : 'basic',
                        link : '/pages/coming-soon/modern-reversed'
                    },
                    {
                        id   : 'pages.coming-soon.split-screen',
                        title: 'Split Screen',
                        type : 'basic',
                        link : '/pages/coming-soon/split-screen'
                    },
                    {
                        id   : 'pages.coming-soon.split-screen-reversed',
                        title: 'Split Screen Reversed',
                        type : 'basic',
                        link : '/pages/coming-soon/split-screen-reversed'
                    },
                    {
                        id   : 'pages.coming-soon.fullscreen',
                        title: 'Fullscreen',
                        type : 'basic',
                        link : '/pages/coming-soon/fullscreen'
                    },
                    {
                        id   : 'pages.coming-soon.fullscreen-reversed',
                        title: 'Fullscreen Reversed',
                        type : 'basic',
                        link : '/pages/coming-soon/fullscreen-reversed'
                    }
                ]
            },
            {
                id      : 'pages.error',
                title   : 'Error',
                type    : 'collapsable',
                icon    : 'heroicons_outline:exclamation-circle',
                children: [
                    {
                        id   : 'pages.error.404',
                        title: '404',
                        type : 'basic',
                        link : '/pages/error/404'
                    },
                    {
                        id   : 'pages.error.500',
                        title: '500',
                        type : 'basic',
                        link : '/pages/error/500'
                    }
                ]
            },
            {
                id      : 'pages.invoice',
                title   : 'Invoice',
                type    : 'collapsable',
                icon    : 'heroicons_outline:calculator',
                children: [
                    {
                        id      : 'pages.invoice.printable',
                        title   : 'Printable',
                        type    : 'collapsable',
                        children: [
                            {
                                id   : 'pages.invoice.printable.compact',
                                title: 'Compact',
                                type : 'basic',
                                link : '/pages/invoice/printable/compact'
                            },
                            {
                                id   : 'pages.invoice.printable.modern',
                                title: 'Modern',
                                type : 'basic',
                                link : '/pages/invoice/printable/modern'
                            }
                        ]
                    }
                ]
            },
            {
                id   : 'pages.maintenance',
                title: 'Maintenance',
                type : 'basic',
                icon : 'heroicons_outline:exclamation',
                link : '/pages/maintenance'
            },
            {
                id      : 'pages.pricing',
                title   : 'Pricing',
                type    : 'collapsable',
                icon    : 'heroicons_outline:cash',
                children: [
                    {
                        id   : 'pages.pricing.modern',
                        title: 'Modern',
                        type : 'basic',
                        link : '/pages/pricing/modern'
                    },
                    {
                        id   : 'pages.pricing.simple',
                        title: 'Simple',
                        type : 'basic',
                        link : '/pages/pricing/simple'
                    },
                    {
                        id   : 'pages.pricing.single',
                        title: 'Single',
                        type : 'basic',
                        link : '/pages/pricing/single'
                    },
                    {
                        id   : 'pages.pricing.table',
                        title: 'Table',
                        type : 'basic',
                        link : '/pages/pricing/table'
                    }
                ]
            },
            {
                id   : 'pages.profile',
                title: 'Profile',
                type : 'basic',
                icon : 'heroicons_outline:user-circle',
                link : '/pages/profile'
            }, */
    ],
  },
];
export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id: "dashboards",
    title: "Dashboards",
    type: "group",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "apps",
    title: "Master",
    type: "group",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "reports",
    title: "REPORT",
    type: "group",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "pages",
    title: "Setting",
    type: "group",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
];
