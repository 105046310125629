import { Route } from "@angular/router";
import { AuthGuard } from "app/core/auth/guards/auth.guard";
import { NoAuthGuard } from "app/core/auth/guards/noAuth.guard";
import { LayoutComponent } from "app/layout/layout.component";
import { InitialDataResolver } from "app/app.resolvers";

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/dashboards/project'
  { path: "", pathMatch: "full", redirectTo: "dashboards/waslogin" },
  // {path: '', pathMatch : 'full', redirectTo: 'apps/karyawan'},

  // Redirect signed in user to the '/dashboards/project'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  {
    path: "signed-in-redirect",
    pathMatch: "full",
    redirectTo: "dashboards/waslogin",
  },

  // Auth routes for guests
  {
    path: "",
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "confirmation-required",
        loadChildren: () =>
          import(
            "app/modules/auth/confirmation-required/confirmation-required.module"
          ).then((m) => m.AuthConfirmationRequiredModule),
      },
      {
        path: "forgot-password",
        loadChildren: () =>
          import(
            "app/modules/auth/forgot-password/forgot-password.module"
          ).then((m) => m.AuthForgotPasswordModule),
      },
      {
        path: "reset-password",
        loadChildren: () =>
          import("app/modules/auth/reset-password/reset-password.module").then(
            (m) => m.AuthResetPasswordModule
          ),
      },
      {
        path: "sign-in",
        loadChildren: () =>
          import("app/modules/auth/sign-in/sign-in.module").then(
            (m) => m.AuthSignInModule
          ),
      },
      {
        path: "sign-up",
        loadChildren: () =>
          import("app/modules/auth/sign-up/sign-up.module").then(
            (m) => m.AuthSignUpModule
          ),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: "",
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "sign-out",
        loadChildren: () =>
          import("app/modules/auth/sign-out/sign-out.module").then(
            (m) => m.AuthSignOutModule
          ),
      },
      {
        path: "unlock-session",
        loadChildren: () =>
          import("app/modules/auth/unlock-session/unlock-session.module").then(
            (m) => m.AuthUnlockSessionModule
          ),
      },
    ],
  },

  // Landing routes
  {
    path: "",
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "home",
        loadChildren: () =>
          import("app/modules/landing/home/home.module").then(
            (m) => m.LandingHomeModule
          ),
      },
      {
        path: "pdf",
        loadChildren: () =>
          import("app/modules/landing/pdf/pdf.module").then(
            (m) => m.LandingHomeModule
          ),
      },
      // 404 & Catch all
      {
        path: "404",
        pathMatch: "full",
        loadChildren: () =>
          import("app/modules/landing/error/error-404/error-404.module").then(
            (m) => m.Error404Module
          ),
      },
    ],
  },

  // Admin routes
  {
    path: "",
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      // Dashboards
      {
        path: "dashboards",
        children: [
          {
            path: "maindash",
            loadChildren: () =>
              import(
                "app/modules/admin/dashboards/maindash/maindash.module"
              ).then((m) => m.MaindashModule),
          },
          {
            path: "project",
            loadChildren: () =>
              import(
                "app/modules/admin/dashboards/project/project.module"
              ).then((m) => m.ProjectModule),
          },
          {
            path: "analytics",
            loadChildren: () =>
              import(
                "app/modules/admin/dashboards/analytics/analytics.module"
              ).then((m) => m.AnalyticsModule),
          },
          {
            path: "finance",
            loadChildren: () =>
              import(
                "app/modules/admin/dashboards/finance/finance.module"
              ).then((m) => m.FinanceModule),
          },
          {
            path: "waslogin",
            loadChildren: () =>
              import(
                "app/modules/admin/dashboards/waslogin/waslogin.module"
              ).then((m) => m.WasloginModule),
          },
        ],
      },

      // Apps
      {
        path: "apps",
        children: [
          {
            path: "karyawan",
            loadChildren: () =>
              import("app/modules/admin/apps/karyawan/karyawan.module").then(
                (m) => m.KaryawanModule
              ),
          },
          {
            path: "hse",
            loadChildren: () =>
              import("app/modules/admin/apps/hse/hse.module").then(
                (m) => m.HseModule
              ),
          },
          {
            path: "custapp",
            loadChildren: () =>
              import("app/modules/admin/apps/custapp/custapp.module").then(
                (m) => m.CustappModule
              ),
          },
          {
            path: "customer",
            loadChildren: () =>
              import("app/modules/admin/apps/customer/customer.module").then(
                (m) => m.CustomerModule
              ),
          },
          {
            path: "sparepart",
            loadChildren: () =>
              import("app/modules/admin/apps/sparepart/sparepart.module").then(
                (m) => m.SparepartModule
              ),
          },
          {
            path: "tugas",
            loadChildren: () =>
              import("app/modules/admin/apps/tugas/tugas.module").then(
                (m) => m.TugasModule
              ),
          },
          {
            path: "temuan",
            loadChildren: () =>
              import("app/modules/admin/apps/temuan/temuan.module").then(
                (m) => m.TemuanModule
              ),
          },
          {
            path: "nonrutin",
            loadChildren: () =>
              import("app/modules/admin/apps/nonrutin/nonrutin.module").then(
                (m) => m.NonrutinModule
              ),
          },
          {
            path: "jadwal",
            loadChildren: () =>
              import("app/modules/admin/apps/jadwal/jadwal.module").then(
                (m) => m.JadwalModule
              ),
          },
          {
            path: "absensi",
            loadChildren: () =>
              import("app/modules/admin/apps/absensi/absensi.module").then(
                (m) => m.AbsensiModule
              ),
          },
          {
            path: "jartest",
            loadChildren: () =>
              import("app/modules/admin/apps/kimia/kimia.module").then(
                (m) => m.KimiaModule
              ),
          },
          {
            path: "announcements",
            loadChildren: () =>
              import("app/modules/admin/apps/announcements/index.module").then(
                (m) => m.AnnouncementModule
              ),
          },
          {
            path: "field-finding-categories",
            loadChildren: () =>
              import(
                "app/modules/admin/apps/field-finding-categories/index.module"
              ).then((m) => m.FieldFindingCategoryModule),
          },
          {
            path: "monthly-reports",
            loadChildren: () =>
              import(
                "app/modules/admin/apps/monthly-reports/index.module"
              ).then((m) => m.MonthlyReportModule),
          },
          {
            path: "sparepart-category",
            loadChildren: () =>
              import(
                "app/modules/admin/apps/sparepart-category/sparepart-category.module"
              ).then((m) => m.SparepartCategoryModule),
          },
          // {path: 'academy', loadChildren: () => import('app/modules/admin/apps/academy/academy.module').then(m => m.AcademyModule)},
          {
            path: "calendar",
            loadChildren: () =>
              import("app/modules/admin/apps/calendar/calendar.module").then(
                (m) => m.CalendarModule
              ),
          },
          // {path: 'chat', loadChildren: () => import('app/modules/admin/apps/chat/chat.module').then(m => m.ChatModule)},
          {
            path: "contacts",
            loadChildren: () =>
              import("app/modules/admin/apps/contacts/contacts.module").then(
                (m) => m.ContactsModule
              ),
          },
          // {path: 'ecommerce', loadChildren: () => import('app/modules/admin/apps/ecommerce/ecommerce.module').then(m => m.ECommerceModule)},
          {
            path: "file-manager",
            loadChildren: () =>
              import(
                "app/modules/admin/apps/file-manager/file-manager.module"
              ).then((m) => m.FileManagerModule),
          },
          {
            path: "help-center",
            loadChildren: () =>
              import(
                "app/modules/admin/apps/help-center/help-center.module"
              ).then((m) => m.HelpCenterModule),
          },
          // {path: 'mailbox', loadChildren: () => import('app/modules/admin/apps/mailbox/mailbox.module').then(m => m.MailboxModule)},
          // {path: 'notes', loadChildren: () => import('app/modules/admin/apps/notes/notes.module').then(m => m.NotesModule)},
          // {path: 'scrumboard', loadChildren: () => import('app/modules/admin/apps/scrumboard/scrumboard.module').then(m => m.ScrumboardModule)},
          {
            path: "tasks",
            loadChildren: () =>
              import("app/modules/admin/apps/tasks/tasks.module").then(
                (m) => m.TasksModule
              ),
          },
        ],
      },

      // Reports
      {
        path: "reports",
        children: [
          // Activities
          {
            path: "rating",
            loadChildren: () =>
              import("app/modules/admin/reports/rating/rating.module").then(
                (m) => m.RatingModule
              ),
          },
          {
            path: "aktivitas",
            loadChildren: () =>
              import(
                "app/modules/admin/reports/aktivitas/aktivitas.module"
              ).then((m) => m.AktivitasModule),
          },
          {
            path: "nrw",
            loadChildren: () =>
              import("app/modules/admin/reports/nrw/nrw.module").then(
                (m) => m.NrwModule
              ),
          },
          {
            path: "kompilasi-produksi",
            loadChildren: () =>
              import(
                "app/modules/admin/reports/kompilasi-produksi/komprod.module"
              ).then((m) => m.KomprodModule),
          },
          {
            path: "sales",
            loadChildren: () =>
              import("app/modules/admin/reports/sales/sales.module").then(
                (m) => m.SalesModule
              ),
          },
          {
            path: "billing",
            loadChildren: () =>
              import("app/modules/admin/reports/billing/billing.module").then(
                (m) => m.BillingModule
              ),
          },
          {
            path: "summary",
            loadChildren: () =>
              import("app/modules/admin/reports/summary/summary.module").then(
                (m) => m.SummaryModule
              ),
          },
        ],
      },

      // Pages
      {
        path: "pages",
        children: [
          {
            path: "settings",
            loadChildren: () =>
              import("app/modules/admin/pages/settings/settings.module").then(
                (m) => m.SettingsModule
              ),
          },

          /*
                // Activities
                {path: 'activities', loadChildren: () => import('app/modules/admin/pages/activities/activities.module').then(m => m.ActivitiesModule)},

                // Authentication
                {path: 'authentication', loadChildren: () => import('app/modules/admin/pages/authentication/authentication.module').then(m => m.AuthenticationModule)},

                // Coming Soon
                {path: 'coming-soon', loadChildren: () => import('app/modules/admin/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule)},

                // Error
                {path: 'error', children: [
                    {path: '404', loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.module').then(m => m.Error404Module)},
                    {path: '500', loadChildren: () => import('app/modules/admin/pages/error/error-500/error-500.module').then(m => m.Error500Module)}
                ]},

                // Invoice
                {path: 'invoice', children: [
                    {path: 'printable', children: [
                        {path: 'compact', loadChildren: () => import('app/modules/admin/pages/invoice/printable/compact/compact.module').then(m => m.CompactModule)},
                        {path: 'modern', loadChildren: () => import('app/modules/admin/pages/invoice/printable/modern/modern.module').then(m => m.ModernModule)}
                    ]}
                ]},

                // Maintenance
                {path: 'maintenance', loadChildren: () => import('app/modules/admin/pages/maintenance/maintenance.module').then(m => m.MaintenanceModule)},

                // Pricing
                {path: 'pricing', children: [
                    {path: 'modern', loadChildren: () => import('app/modules/admin/pages/pricing/modern/modern.module').then(m => m.PricingModernModule)},
                    {path: 'simple', loadChildren: () => import('app/modules/admin/pages/pricing/simple/simple.module').then(m => m.PricingSimpleModule)},
                    {path: 'single', loadChildren: () => import('app/modules/admin/pages/pricing/single/single.module').then(m => m.PricingSingleModule)},
                    {path: 'table', loadChildren: () => import('app/modules/admin/pages/pricing/table/table.module').then(m => m.PricingTableModule)}
                ]},

                // Profile
                {path: 'profile', loadChildren: () => import('app/modules/admin/pages/profile/profile.module').then(m => m.ProfileModule)}, */
        ],
      },

      // 404 & Catch all
      {
        path: "404-not-found",
        pathMatch: "full",
        loadChildren: () =>
          import(
            "app/modules/admin/pages/error/error-404/error-404.module"
          ).then((m) => m.Error404Module),
      },
      { path: "**", redirectTo: "404-not-found" },
    ],
  },
];
