import { Layout } from "app/layout/layout.types";

// Types
export type Scheme = "auto" | "dark" | "light";
export type Theme = "default" | string;

/**
 * AppConfig interface. Update this interface to strictly type your config
 * object.
 */
export interface AppConfig {
  layout: Layout;
  scheme: Scheme;
  theme: Theme;
  projectName: string;
  collectionNames: any;
}

/**
 * Default configuration for the entire application. This object is used by
 * FuseConfigService to set the default configuration.
 *
 * If you need to store global configuration for your app, you can use this
 * object to set the defaults. To access, update and reset the config, use
 * FuseConfigService and its methods.
 */
export const appConfig: AppConfig = {
  layout: "futuristic",
  scheme: "light",
  theme: "default",
  projectName: "Aguadigi",
  collectionNames: {
    dbUsers: "users",
    dbDepartements: "departements",
    dbCustomers: "customers",
    dbCustApp: "custApps",
    dbFcmTokens: "fcmTokens",
    dbFieldFindingCategories: "fieldFindingCategories",
    dbExternalLabReports: "announcements",
    dbMonthlyReports: "monthlyReports",
    dbSpareParts: "spareparts",
    dbTasks: "tasks",
    dbTaskComments: "taskComments",
    dbShifts: "shifts",
    dbKimia: "kimia",
    dbSchedules: "schedules",
    dbSettingReports: "settingReports",
    dbTaskReports: "taskReports",
    dbShiftTemplates: "shiftTemplates",
    dbHse: "hse",
    dbAttendances: "attendances",
    dbNtu: "ntu",
    dbRole: "role",
    dbReset: "reset", // reset meter
    dbInvoice: "invoice",
    dbLastDate: "lastDate", // simpan pencatatan akhir bulan
    dbInvNo: "invoiceNo", // simpan last invoice number
    dbInvDateDomestik: "invoiceDateDomestik", // simpan last invoice number
    dbTemuan: "temuan",
    dbNrwReport: "nrwReport",
    dbScheduleChanges: "scheduleChanges",
    dbSparePartCategories: "sparepartcategories",
  },
};
