import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { AngularFireAuth } from '@angular/fire/auth';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import { HmacSHA256 } from 'crypto-js';

@Injectable()
export class AuthService
{
    private _authenticated: boolean = false;
    private readonly _secret: any;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private afAuth: AngularFireAuth
    )
    {
        this._secret = 'KONFIDENTAL_00T_SECRETE_KEY_GRAFIHOUSE!!!';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }
    set currentUser(data: string)
    {
        localStorage.setItem('curuser', data);
    }

    get currentUser(): string
    {
        return localStorage.getItem('curuser') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any>
    {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any>
    {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    setLoginTrue(token: string,data: any, role: any): void{
        // Store the access token in the local storage
        this.accessToken = token;

        // Set the authenticated flag to true
        this._authenticated = true;
        // Store the user on the user service
        const filterData = {
            'id': data.id,
            'name': data.name,
            'email': data.email,
            'dept': data.departementId,
            'roles': role.roles
        };
        this._userService.user = filterData;
        this.currentUser = JSON.stringify(filterData);
    }
    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Promise<any>
    {
        // Throw error, if the user is already logged in
        /* if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        } */
        return this.afAuth.signInWithEmailAndPassword(credentials.email, credentials.password)
            .then(responseFb =>
                // console.log('LoginUser: success');
                // console.log(responseFb);
                 ({
                    isValid:true,
                    uid: responseFb.user.uid,
                    accessToken: this._generateJWTToken()
                } )
            )
            .catch(error =>
                // console.log('Auth Service: loginnya error...');
                // console.log('errornyi', error);
                 ({ isValid: false, message: error.message })
            );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        // console.log('sign with token');
        // Renew token
        return this._httpClient.post('api/auth/refresh-access-token', {
            accessToken: this.accessToken
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;
                this.currentUser = JSON.stringify(response.user);

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('curuser');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Return base64 encoded version of the given string
     *
     * @param source
     * @private
     */
     private _base64url(source: any): string
     {
         // Encode in classical base64
         let encodedSource = Base64.stringify(source);

         // Remove padding equal characters
         encodedSource = encodedSource.replace(/=+$/, '');

         // Replace characters according to base64url specifications
         encodedSource = encodedSource.replace(/\+/g, '-');
         encodedSource = encodedSource.replace(/\//g, '_');

         // Return the base64 encoded string
         return encodedSource;
     }

     /**
      * Generates a JWT token using CryptoJS library.
      *
      * This generator is for mocking purposes only and it is NOT
      * safe to use it in production frontend applications!
      *
      * @private
      */
     private _generateJWTToken(): string
     {
         // Define token header
         const header = {
             alg: 'HS256',
             typ: 'JWT'
         };

         // Calculate the issued at and expiration dates
         const date = new Date();
         const iat = Math.floor(date.getTime() / 1000);
         const exp = Math.floor((date.setDate(date.getDate() + 7)) / 1000);

         // Define token payload
         const payload = {
             iat: iat,
             iss: 'Fuse',
             exp: exp
         };

         // Stringify and encode the header
         const stringifiedHeader = Utf8.parse(JSON.stringify(header));
         const encodedHeader = this._base64url(stringifiedHeader);

         // Stringify and encode the payload
         const stringifiedPayload = Utf8.parse(JSON.stringify(payload));
         const encodedPayload = this._base64url(stringifiedPayload);

         // Sign the encoded header and mock-api
         let signature: any = encodedHeader + '.' + encodedPayload;
         signature = HmacSHA256(signature, this._secret);
         signature = this._base64url(signature);

         // Build and return the token
         return encodedHeader + '.' + encodedPayload + '.' + signature;
     }

     /**
      * Verify the given token
      *
      * @param token
      * @private
      */
     private _verifyJWTToken(token: string): boolean
     {
         // Split the token into parts
         const parts = token.split('.');
         const header = parts[0];
         const payload = parts[1];
         const signature = parts[2];

         // Re-sign and encode the header and payload using the secret
         const signatureCheck = this._base64url(HmacSHA256(header + '.' + payload, this._secret));

         // Verify that the resulting signature is valid
         return (signature === signatureCheck);
     }
}
