// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  forMobile: false,
  forOxi: false,

  baseUrl: "https://stage-aguadigi.com",
  pathApi: "https://us-central1-stage-aguadigi.cloudfunctions.net/",
  firebase: {
    apiKey: "AIzaSyBg0bH6-RDsF_UEK6wFJR5aHnVoNBTcBKs",
    authDomain: "stage-aguadigi.firebaseapp.com",
    projectId: "stage-aguadigi",
    storageBucket: "stage-aguadigi.appspot.com",
    messagingSenderId: "32489566240",
    appId: "1:32489566240:web:0d47182bbc8593ba110c45",
    measurementId: "G-V61LV135CV",
  },
};

// export const environment = {
//   production: true,
//   forMobile: false, //pakai true kalau mau convert ke mobile aja
//   forOxi: false,

//   baseUrl: "https://aguadigi.com",
//   pathApi: "https://us-central1-aguadigi.cloudfunctions.net/",
//   firebase: {
//     apiKey: "AIzaSyDFi9pzdjgh5Tf-8wBu_HMRrPECWINrC8I",
//     authDomain: "aguadigi.firebaseapp.com",
//     projectId: "aguadigi",
//     storageBucket: "aguadigi.appspot.com",
//     messagingSenderId: "944109020248",
//     appId: "1:944109020248:web:435e70ba26f7d88e5b562d",
//     measurementId: "G-Q8XV2RGDBZ",
//   },
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
