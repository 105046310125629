import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { defaultNavigation, futuristicNavigation } from 'app/mock-api/common/navigation/data';
import { MyhelperService } from 'app/shared/myhelper.service';
import { roleSuperAdminCode } from 'app/shared/myconstanta';

@Injectable({
    providedIn: 'root'
})
export class NavigationMockApi
{
    private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
    private readonly _futuristicNavigation: FuseNavigationItem[] = futuristicNavigation;

    /**
     * Constructor
     */
    constructor(
        private _fuseMockApiService: FuseMockApiService,
        private _myHelper: MyhelperService,
        )
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        this._fuseMockApiService
            .onGet('api/common/navigation')
            .reply(() => {
                const roles = this._myHelper.getCurRoles();
                // Fill futuristic navigation children using the default navigation
                this._futuristicNavigation.forEach((futuristicNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === futuristicNavItem.id )
                         {
                             if(roles === roleSuperAdminCode){ //munculkan semua menu
                                futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                             }else{
                                defaultNavItem.children.forEach((v)=>{
                                    if(roles.indexOf(v.id)>=0){
                                        if(v.children){
                                            const newChild = [];
                                            v.children.forEach((c)=>{
                                                if(roles.indexOf(c.id)>=0){
                                                    newChild.push(c);
                                                }
                                            });
                                            v.children = newChild;
                                        }
                                        futuristicNavItem.children.push(v);
                                    }
                                    if(v.id === 'pages.settings'){
                                        futuristicNavItem.children.push(v);
                                    }
                                });
                             }
                        }
                    });
                });

                // Return the response
                return [
                    200,
                    {
                        // compact   : cloneDeep(this._compactNavigation),
                        // default   : cloneDeep(this._defaultNavigation),
                        futuristic: cloneDeep(this._futuristicNavigation),
                        // horizontal: cloneDeep(this._horizontalNavigation)
                    }
                ];
            });
    }
}
