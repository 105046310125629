import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { appConfig } from "app/core/config/app.config";
import CryptoJS from "crypto-js";
import _, {
  countBy,
  groupBy,
  isEmpty,
  isUndefined,
  round,
  sumBy,
} from "lodash";
import moment from "moment";
import { Observable, Subject } from "rxjs";
import { take } from "rxjs/operators";
import {
  deptHirarcy,
  hargaDuapuluh,
  hargaSepuluh,
  hargaTengah,
  intakePompa,
  jabatan,
  keyKap,
  keyKapA,
  keyKapAPmk,
  keyKapB,
  keyKapBPmk,
  keyKapPmk,
  keyMeterKap,
  keyMeterKapPmk,
  keyMeterPac,
  keyMeterPacPmk,
  keyPac,
  keyPacA,
  keyPacAPmk,
  keyPacB,
  keyPacBPmk,
  keyPacPmk,
  keyPolA,
  keyPolAPmk,
  keyPolB,
  keyPolBPmk,
  meterKap,
  meterPac,
  wtp1BakuId,
  wtp1Cikande,
  wtp1DayaKwh,
  wtp1PomDist,
  wtp1R2r1ke3,
  wtp1TangkiKap,
  wtp1TangkiPac,
  wtp2BakuId,
  wtp2Produksi,
  wtp3BakuId,
  wtp3Carenang,
  wtp3Cijeruk,
  wtp3Industri,
  wtp3PomDist,
  wtp3PomTransfer,
  wtp3R2r3ke1,
  wtp3TangkiKap,
  wtp3TangkiPac,
  wtp3TangkiPol,
} from "./myconstanta";

@Injectable({
  providedIn: "root",
})
export class MyhelperService {
  dbTaskReports: string = appConfig.collectionNames.dbTaskReports;
  dbCustomer: string = appConfig.collectionNames.dbCustomers;
  dbUsers: string = appConfig.collectionNames.dbUsers;
  dbLastDate: string = appConfig.collectionNames.dbLastDate;
  dbNrwReport: string = appConfig.collectionNames.dbNrwReport;

  repetitions: any = ["Tiap Jam", "Tiap 4 Jam"];

  constructor(private _afs: AngularFirestore) {
    // this.price = this.getPrice().subscribe((x) => (this.price = x));
  }

  getCurUser(): any {
    const curUser: any = JSON.parse(localStorage.getItem("curuser"));
    const tgl = +new Date();

    return {
      id: curUser.id,
      name: curUser.name,
      email: curUser.email,
      date: tgl,
      deptId: curUser.dept,
    };
  }
  convertDateHuman(tgl: string, withTime = true): string {
    if (withTime) {
      return moment(tgl).format("DD MMM YYYY HH:mm");
    } else {
      return moment(tgl).format("DD MMM YYYY");
    }
  }
  getCurRoles(): any {
    const curUser: any = JSON.parse(localStorage.getItem("curuser"));
    return curUser.roles;
  }
  getCurDeptId(): string {
    const curUser: any = JSON.parse(localStorage.getItem("curuser"));
    return curUser.dept;
  }
  getUser(userId: string): Observable<any[]> {
    return this._afs
      .collection<any>(this.dbUsers)
      .doc(userId)
      .valueChanges({ idField: "id" });
  }
  generateDocId(prefix: string): string {
    return prefix + moment().format("YYMMDDHHmmss");
  }
  getChildsJabatan(): any {
    const curDeptId = Number(this.getCurDeptId());
    if (curDeptId === -1) {
      return jabatan;
    } else {
      const mDept = deptHirarcy.find((v) => v.parent === curDeptId);
      if (mDept) {
        const deptStr = mDept.childs.map((v) => v + "");
        return jabatan.filter((v) => deptStr.indexOf(v.id) >= 0);
      } else {
        // jika tidak punya childs tampilin dept nya sendiri
        return jabatan.filter((v) => v.id === curDeptId + "");
      }
    }
  }

  cutName(str: string): string {
    if (str.indexOf(" ") > 0) {
      str = str.split(" ")[0];
    } else {
      str = str.substring(0, 6);
    }
    return str;
  }
  fbLastDate(): Observable<any> {
    //limatahun kebelakang
    const year = Number(moment().format("YYYY"));
    return this._afs
      .collection<any>(this.dbLastDate, (ref) =>
        ref.where("year", "<=", year).where("year", ">=", year - 5)
      )
      .get();
  }
  fbReportSales(
    startMonth: number,
    endMonth: number,
    custId?: string
  ): Observable<any[]> {
    // console.log('di fb : '+custId +' '+startMonth+'=='+endMonth);
    if (custId) {
      return this._afs
        .collection<any>(this.dbTaskReports, (ref) =>
          ref
            .where("location", "==", "pelanggan")
            .where("id", ">=", custId + "-" + startMonth)
            .where("id", "<=", custId + "-" + endMonth)
            .orderBy("id", "asc")
        )
        .valueChanges({ idField: "id" });
    } else {
      return this._afs
        .collection<any>(this.dbTaskReports, (ref) =>
          ref
            .where("location", "==", "pelanggan")
            .where("forDate", ">=", Number(startMonth))
            .where("forDate", "<=", Number(endMonth))
            .orderBy("forDate", "asc")
        )
        .valueChanges({ idField: "id" });
    }
  }
  fbCustomer(): Observable<any[]> {
    return this._afs
      .collection<any>(this.dbCustomer)
      .valueChanges({ idField: "id" });
  }
  fbReportProduksi(
    startDate,
    endDate,
    includeIntake = true
  ): Observable<any[]> {
    let criteria = ["wtp 1", "wtp 3"];
    if (includeIntake) {
      criteria = ["wtp 1", "wtp 3", "intake"];
    }
    return this._afs
      .collection<any>(this.dbTaskReports, (ref) =>
        ref
          .where("pelaksana", "==", "operator")
          .where("location", "in", criteria)
          .where("forDate", ">=", Number(startDate))
          .where("forDate", "<=", Number(endDate))
          .orderBy("forDate", "asc")
      )
      .valueChanges({ idField: "id" });
  }
  getReportSales(startMonth, endMonth, custId = null): Observable<any> {
    //ambil sampai mounth aja
    const subject = new Subject<any>();
    const fs = this.fbReportSales(startMonth, endMonth, custId)
      .pipe(take(1))
      .subscribe((next: any) => {
        const newVal = [];
        next.forEach((dt) => {
          newVal.push(this.simpleSales(dt));
        });
        subject.next(newVal);
        // console.log('newVal',newVal);
        fs.unsubscribe();
      });

    return subject.asObservable();
  }
  simpleSales(dt: any): any {
    const col = _.keys(dt);
    let row: any = [];
    col.forEach((c) => {
      if (!isNaN(Number(c))) {
        //jika number maka ambil value nya aja
        const autoCalc = dt[c]["details"].find((v) => v.type === "autocalc");
        const reset = dt[c]["details"].find(
          (v) => v.resetId && v.name === "reset"
        );
        let pmk = 0;
        if (autoCalc) {
          pmk = Number(autoCalc["value"]);
        }
        row = {
          ...row,
          ...{
            [c]: {
              meter: Number(dt[c]["details"][0]["value"]),
              m3: pmk,
              reset: reset ? 1 : 0,
            },
          },
        }; //angka meter
      } else {
        row = { ...row, ...{ [c]: dt[c] } };
      }
    });
    row = {
      ...row,
      ...{ ["customerId"]: dt["id"].substring(0, dt["id"].indexOf("-")) },
    };
    return row;
  }

  //===========================================  total pemakaian wtp 1 =============================
  getWTP1Total(startDate, endDate): Observable<any> {
    const subject = new Subject<any>();
    const fs = this.getSingleRow("wtp 1", startDate, endDate)
      .pipe(take(1))
      .subscribe((next: any) => {
        let bakuAwal = 0;
        let produksiAwal = 0;
        if (next) {
          bakuAwal = next[0][wtp1BakuId]["details"][0]["value"];
          produksiAwal = next[0][wtp1Cikande]["details"][0]["value"]; //cikande
        }
        fs.unsubscribe();

        const fs2 = this.getSingleRow("wtp 1", startDate, endDate, false)
          .pipe(take(1))
          .subscribe((rs: any) => {
            let bakuAkhir = 0;
            let produksiAkhir = 0;
            if (rs) {
              bakuAkhir = rs[0][wtp1BakuId]["details"][0]["value"];
              produksiAkhir = rs[0][wtp1Cikande]["details"][0]["value"];

              const baku = bakuAkhir - bakuAwal;
              const produksi = produksiAkhir - produksiAwal;

              if (Number(baku) < 0 || Number(produksi) < 0) {
                fs2.unsubscribe();
                this.getDeepTotalPemakaianWtp1(startDate, endDate)
                  .pipe(take(1))
                  .subscribe((dtDeep) => {
                    subject.next(dtDeep);
                  });
              } else {
                subject.next({ baku: baku, cikande: produksi });
              }
            }
            fs2.unsubscribe();
          });
      });

    return subject.asObservable();
  }
  groupingCustomerStatus(source: any): any {
    const ans = _(source)
      .countBy("status")
      .map((data, id) => ({
        status: id,
        count: data,
      }))
      .value();
    return ans;
  }
  groupingSalesPerCustomer(source: any): any {
    const ans = _(source)
      .groupBy("month")
      .map((data, id) => ({
        tanggal: id,
        month: data[0]["month"],
        pemakaian: _.sumBy(data, "pemakaian"),
        rupiah: _.maxBy(data, "rupiah").rupiah,
      }))
      .value();
    return this.cleanArray(ans);
  }
  groupingWtp1(source: any, groupName = "tanggal"): any {
    const ans = _(source)
      .groupBy(groupName)
      .map((data, id) => ({
        tanggal: id,
        month: data[0]["month"],
        pmkBaku: _.sumBy(data, "pmkBaku"),
        pmkCikande: _.sumBy(data, "pmkCikande"),
      }))
      .value();
    return this.cleanArray(ans);
  }
  groupingWtp2(source: any, groupName = "tanggal"): any {
    const ans = _(source)
      .groupBy(groupName)
      .map((data, id) => ({
        tanggal: id,
        month: data[0]["month"],
        pmkBaku: _.sumBy(data, "pmkBaku"),
        pmkCikande: _.sumBy(data, "pmkProduksi"),
      }))
      .value();
    return this.cleanArray(ans);
  }
  groupingWtp3(source: any, groupName = "tanggal"): any {
    const ans = _(source)
      .groupBy(groupName)
      .map((data, id) => ({
        tanggal: id,
        month: data[0]["month"],
        pmkBaku: _.sumBy(data, "pmkBakuWtp3"),
        pmkIndustri: _.sumBy(data, "pmkIndustri"),
        pmkCarenang: _.sumBy(data, "pmkCarenang"),
        pmkCijeruk: _.sumBy(data, "pmkCijeruk"),
      }))
      .value();
    // console.log('ans');
    // console.log(ans);

    return this.cleanArray(ans);
  }
  getDeepTotalPemakaianWtp1(startDate, endDate): Observable<any> {
    const subject = new Subject<any>();
    const fs = this.getTotalPemakaianWtp1(startDate, endDate)
      .pipe(take(1))
      .subscribe((next) => {
        //// console.log(next);
        if (isEmpty(next)) {
          subject.next({ baku: 0, cikande: 0, r2r1ke3: 0, details: null });
        } else {
          const tbl = this.generateTableNumericOnly(next, [
            wtp1BakuId,
            wtp1Cikande,
            wtp1R2r1ke3,
          ]);
          const totalPemakaian = this.pemakaianWTP1(tbl);
          //// console.log(totalPemakaian);

          const totBaku =
            totalPemakaian[totalPemakaian.length - 1]["totalBaku"];
          const totProduksi =
            totalPemakaian[totalPemakaian.length - 1]["totalCikande"];
          const totR2R =
            totalPemakaian[totalPemakaian.length - 1]["totalR2R1ke3"];
          subject.next({
            baku: totBaku,
            cikande: totProduksi,
            r2r1ke3: totR2R,
            details: this.groupingWtp1(totalPemakaian),
          });
        }
        fs.unsubscribe();
      });
    return subject.asObservable();
  }
  getDeepTotalPemakaianWtp2(startDate, endDate): Observable<any> {
    const subject = new Subject<any>();
    const fs = this.getTotalPemakaianWtp2(startDate, endDate)
      .pipe(take(1))
      .subscribe((next) => {
        //// console.log(next);
        if (isEmpty(next)) {
          subject.next({ baku: 0, produksi: 0, details: null });
        } else {
          const tbl = this.generateTableNumericOnly(next, [
            wtp2BakuId,
            wtp2Produksi,
          ]);
          const totalPemakaian = this.pemakaianWTP2(tbl);
          //// console.log(totalPemakaian);

          const totBaku =
            totalPemakaian[totalPemakaian.length - 1]["totalBaku"];
          const totProduksi =
            totalPemakaian[totalPemakaian.length - 1]["totalProduksi"];
          subject.next({
            baku: totBaku,
            produksi: totProduksi,
            details: this.groupingWtp2(totalPemakaian),
          });
        }
        fs.unsubscribe();
      });
    return subject.asObservable();
  }

  getTotalPemakaianWtp1(startDate, endDate): Observable<any[]> {
    return this._afs
      .collection<any>(this.dbTaskReports, (ref) =>
        ref
          .where("pelaksana", "==", "operator")
          .where("location", "==", "wtp 1")
          .where("forDate", ">=", Number(startDate))
          .where("forDate", "<=", Number(endDate))
      )
      .valueChanges({ idField: "id" });
  }
  getTotalPemakaianWtp2(startDate, endDate): Observable<any[]> {
    return this._afs
      .collection<any>(this.dbTaskReports, (ref) =>
        ref
          .where("pelaksana", "==", "operator")
          .where("location", "==", "wtp 2")
          .where("forDate", ">=", Number(startDate))
          .where("forDate", "<=", Number(endDate))
      )
      .valueChanges({ idField: "id" });
  }
  // end ==========================================  total pemakaian wtp 1 =============================
  getDeepKimia(startDate, endDate): Observable<any> {
    const subject = new Subject<any>();
    const fs = this.fbReportProduksi(startDate, endDate, false)
      .pipe(take(1))
      .subscribe((next) => {
        //// console.log(next);
        //// console.log('nexxxt kimia');
        if (isEmpty(next)) {
          subject.next(null);
        } else {
          // const tbl = this.generateTableNumericOnly(next,[wtp1TangkiPac,wtp1TangkiKap,wtp3TangkiPac,wtp3TangkiKap,wtp3TangkiPol]);
          const tbl = this.generateTableNumericOnly(next, [
            meterPac,
            meterKap,
            wtp3TangkiPol,
            wtp3BakuId,
            wtp3Industri,
            wtp3Carenang,
            wtp3Cijeruk,
            wtp3R2r3ke1,
            wtp1Cikande,
            wtp1R2r1ke3,
          ]);
          fs.unsubscribe();
          let dataWtp1 = tbl.filter((it) => it.location === "wtp 1");
          let dataWtp3 = tbl.filter((it) => it.location === "wtp 3");
          //// console.log(tbl);

          // dataWtp1 = this.pemakaianKimiaWtp1(dataWtp1);
          dataWtp3 = this.pemakaianMeterKimia(dataWtp3);
          dataWtp1 = this.pemakaianMeterKimia(dataWtp1);
          const dataPmkWtp3 = this.pemakaianWTP3(dataWtp3);
          const dataPmkWtp1 = this.pemakaianWTP1(dataWtp1);
          // console.log('pmmkWtp3', dataPmkWtp3[dataPmkWtp3.length-1]);
          // console.log('pmmkWtp1', dataPmkWtp1[dataPmkWtp1.length-1]);
          let totAirBaku = 0;
          let prodWtp1 = 0;
          let prodWtp3 = 0;
          let wtp3;
          let wtp1;
          if (!isUndefined(dataPmkWtp3[dataPmkWtp3.length - 1])) {
            wtp3 = dataPmkWtp3[dataPmkWtp3.length - 1];
            totAirBaku = wtp3.totalBaku;
          }

          // const produksiWtp3 = (Number(wtp3.industri)+Number(wtp3.carenang)+
          //Number(wtp3.cijeruk)+Number(wtp3.r2r3ke1)) - Number(wtp2.produksi) - Number(next.r2r1ke3);
          // const produksiWtp1 = (Number(next.cikande) + Number(next.r2r1ke3)) - Number(wtp3.r2r3ke1);

          if (!isUndefined(dataPmkWtp1[dataPmkWtp1.length - 1])) {
            wtp1 = dataPmkWtp1[dataPmkWtp1.length - 1];
            // eslint-disable-next-line max-len
            prodWtp3 =
              Number(wtp3.totalIndustri) +
              Number(wtp3.totalCarenang) +
              Number(wtp3.totalCijeruk) +
              Number(wtp3.totalR2R3ke1) -
              Number(wtp1.totalR2R1ke3);
            prodWtp1 =
              Number(wtp1.totalCikande) +
              Number(wtp1.totalR2R1ke3) -
              Number(wtp3.totalR2R3ke1);
          }

          // console.log('prodWtp3 dam 1', prodWtp3, prodWtp1);

          // const totalPemakaian = _.concat(dataWtp1,dataWtp3);
          // const totalPemakaian = dataWtp3;
          // console.log('kimia pemakaain');
          // console.log('dataWtp3', dataWtp3);
          // console.log('totAirbaku', totAirBaku);
          let group = this.groupingKimia(dataWtp3);
          // const arrGroup = [];
          group = group.map((el) => {
            const a = {
              ...el,
              airBaku: totAirBaku,
              prodWtp3: prodWtp3,
              prodWtp1: prodWtp1,
            };
            // console.log('foreaach m', a);
            return a;
          });

          // console.log('groupo--->',group);
          subject.next(group);
          fs.unsubscribe();
        }
        fs.unsubscribe();
      });
    return subject.asObservable();
  }

  groupingKimia(source): any {
    const ans = _(source)
      .groupBy("month")
      .map((data, id) => ({
        date: Number(id),
        /*                 wtp1pac: _.sumBy(data, keyPacPmk),
                wtp1kap: _.sumBy(data, keyKapPmk),
                wtp3pacA: _.sumBy(data, keyPacAPmk),
                wtp3pacB: _.sumBy(data, keyPacBPmk),
                wtp3kapA: _.sumBy(data, keyKapAPmk),
                wtp3kapB: _.sumBy(data, keyKapBPmk),
 */
        pac: _.sumBy(data, keyMeterPacPmk),
        kap: _.sumBy(data, keyMeterKapPmk),
        wtp3polA: _.sumBy(data, keyPolAPmk),
        wtp3polB: _.sumBy(data, keyPolBPmk),
      }))
      .value();

    return this.cleanArray(ans);
  }

  getDeepAktivitasPompa(startDate, endDate): Observable<any> {
    const subject = new Subject<any>();
    const fs = this.fbReportProduksi(startDate, endDate)
      .pipe(take(1))
      .subscribe((next) => {
        //// console.log('nexxxt');
        if (isEmpty(next)) {
          subject.next(null);
        } else {
          const tbl = this.generateTableNumericOnly(next, [
            intakePompa,
            wtp1PomDist,
            wtp3PomDist,
            wtp3PomTransfer,
          ]);
          fs.unsubscribe();
          const dataWtp1 = tbl.filter((it) => it.location === "wtp 1");
          const dataWtp3 = tbl.filter((it) => it.location === "wtp 3");
          const dataIntake = tbl.filter((it) => it.location === "intake");

          const totalPemakaian = _.concat(dataWtp1, dataWtp3, dataIntake);
          const group = this.groupingAktPompa(totalPemakaian);
          subject.next(group);
        }
        fs.unsubscribe();
      });
    return subject.asObservable();
  }
  groupingAktPompa(source): any {
    const ans = _(source)
      .groupBy("month")
      .map((data, id) => ({
        date: Number(id),
        intakePomA: _.sumBy(data, "a1-" + intakePompa),
        intakePomB: _.sumBy(data, "a2-" + intakePompa),
        intakePomC: _.sumBy(data, "a3-" + intakePompa),
        intakePomD: _.sumBy(data, "a4-" + intakePompa),
        intakePomE: _.sumBy(data, "a5-" + intakePompa),
        wtp1PomDistA: _.sumBy(data, "16345230002330-" + wtp1PomDist),
        wtp1PomDistB: _.sumBy(data, "16345230002331-" + wtp1PomDist),
        wtp3PomDistA: _.sumBy(data, "16340288639590-" + wtp3PomDist),
        wtp3PomDistB: _.sumBy(data, "16340288639591-" + wtp3PomDist),
        wtp3PomJockA: _.sumBy(data, "16340288639592-" + wtp3PomDist),
        wtp3PomJockB: _.sumBy(data, "16340288639593-" + wtp3PomDist),
        wtp3PomTransA: _.sumBy(data, "16340285934520-" + wtp3PomTransfer),
        wtp3PomTransB: _.sumBy(data, "16340285934521-" + wtp3PomTransfer),
      }))
      .value();

    return this.cleanArray(ans);
  }

  cleanArray(ans): any {
    //make sure array return no undifined anymore
    const result = [];
    ans.forEach((it) => {
      const itemx = [];
      Object.entries(it).forEach(([key, value]) => {
        itemx[key] = value === undefined ? 0 : value;
      });
      result.push(itemx);
    });
    return result;
  }

  getDeepTotalWtpNSales(
    startDate,
    endDate,
    realPmkCust = false
  ): Observable<any> {
    const subject = new Subject<any>();
    const fs = this.getTotalPemakaianWtp13(startDate, endDate)
      .pipe(take(1))
      .subscribe((next) => {
        //// console.log(next);
        //// console.log('nexxxt');
        if (isEmpty(next)) {
          subject.next(null);
        } else {
          const tbl = this.generateTableNumericOnly(next, [
            wtp1BakuId,
            wtp1Cikande,
            wtp3BakuId,
            wtp3Industri,
            wtp3Carenang,
            wtp3Cijeruk,
          ]);
          fs.unsubscribe();
          let dataWtp1 = tbl.filter((it) => it.location === "wtp 1");
          dataWtp1 = this.pemakaianWTP1(dataWtp1);
          let dataWtp3 = tbl.filter((it) => it.location === "wtp 3");
          // console.log('dataWtp3');
          // console.log(dataWtp1);
          dataWtp3 = this.pemakaianWTP3(dataWtp3);

          const fsSales = this.getReportSales(
            startDate.toString().substr(0, 6),
            endDate.toString().substr(0, 6)
          )
            .pipe(take(1))
            .subscribe((nsales) => {
              const pmkCust = this.pemakaianCustomer(
                nsales,
                startDate.toString().substr(0, 6),
                realPmkCust
              );
              const sales = pmkCust["salesByDate"];
              const totalPemakaian = _.concat(dataWtp1, dataWtp3, sales);
              const group = this.groupingWtp13(totalPemakaian);
              subject.next(group);
              fsSales.unsubscribe();
            });
        }
        fs.unsubscribe();
      });
    return subject.asObservable();
  }

  groupingWtp13(source): any {
    const ans = _(source)
      .groupBy("month")
      .map((data, id) => ({
        date: Number(id),
        pmkBaku: _.sumBy(data, "pmkBaku"),
        pmkCikande: _.sumBy(data, "pmkCikande"),
        pmkBakuWtp3: _.sumBy(data, "pmkBakuWtp3"),
        pmkIndustri: _.sumBy(data, "pmkIndustri"),
        pmkCarenang: _.sumBy(data, "pmkCarenang"),
        pmkCijeruk: _.sumBy(data, "pmkCijeruk"),
        sales: _.sumBy(data, "pemakaian"),
        rupiah: _.sumBy(data, "rupiah"),
        rupiahSepuluh: _.sumBy(data, "rupiahSepuluh"),
        rupiahTengah: _.sumBy(data, "rupiahTengah"),
        rupiahDuapuluh: _.sumBy(data, "rupiahDuapuluh"),
        m3Total: _.sumBy(data, "m3Total"), //total pemakaian jika pakai rumus kuangan
        m3Sepuluh: _.sumBy(data, "m3Sepuluh"),
        m3Tengah: _.sumBy(data, "m3Tengah"),
        m3Duapuluh: _.sumBy(data, "m3Duapuluh"),
      }))
      .value();

    return this.cleanArray(ans);
  }

  getTotalPemakaianWtp13(startDate, endDate): Observable<any[]> {
    return this._afs
      .collection<any>(this.dbTaskReports, (ref) =>
        ref
          .where("pelaksana", "==", "operator")
          .where("location", "in", ["wtp 1", "wtp 3"])
          .where("forDate", ">=", Number(startDate))
          .where("forDate", "<=", Number(endDate))
          .orderBy("forDate", "asc")
      )
      .valueChanges({ idField: "id" });
  }
  //===========================================  total pemakaian wtp 1 =============================
  getWTP3Total(startDate, endDate): Observable<any> {
    const subject = new Subject<any>();
    const fs = this.getSingleRow("wtp 3", startDate, endDate)
      .pipe(take(1))
      .subscribe((next: any) => {
        let bakuAwal = 0;
        let industriAwal = 0;
        let carenangAwal = 0;
        let cijerukAwal = 0;
        if (next) {
          bakuAwal = next[0][wtp3BakuId]["details"][0]["value"];
          industriAwal = next[0][wtp3Industri]["details"][0]["value"];
          carenangAwal = next[0][wtp3Carenang]["details"][0]["value"];
          cijerukAwal = next[0][wtp3Cijeruk]["details"][0]["value"];
        }
        fs.unsubscribe();

        const fs2 = this.getSingleRow("wtp 3", startDate, endDate, false)
          .pipe(take(1))
          .subscribe((rs: any) => {
            let bakuAkhir = 0;
            let industriAkhir = 0;
            let carenangAkhir = 0;
            let cijerukAkhir = 0;
            if (rs) {
              bakuAkhir = rs[0][wtp3BakuId]["details"][0]["value"];
              industriAkhir = rs[0][wtp3Industri]["details"][0]["value"];
              carenangAkhir = rs[0][wtp3Carenang]["details"][0]["value"];
              cijerukAkhir = rs[0][wtp3Cijeruk]["details"][0]["value"];

              const baku = bakuAkhir - bakuAwal;
              const industri = industriAkhir - industriAwal;
              const carenang = carenangAkhir - carenangAkhir;
              const cijeruk = cijerukAkhir - cijerukAwal;

              if (
                Number(baku) < 0 ||
                Number(industri) < 0 ||
                Number(carenang) < 0 ||
                Number(cijeruk) < 0
              ) {
                fs2.unsubscribe();
                this.getDeepTotalPemakaianWtp3(startDate, endDate)
                  .pipe(take(1))
                  .subscribe((dtDeep) => {
                    //// console.log('get wtp3total');
                    subject.next(dtDeep);
                  });
              } else {
                subject.next({
                  baku: baku,
                  industri: industri,
                  carenang: carenang,
                  cijeruk: cijeruk,
                });
              }
            }
            fs2.unsubscribe();
          });
      });

    return subject.asObservable();
  }
  getDeepTotalPemakaianWtp3(
    startDate,
    endDate,
    groupName = "tanggal"
  ): Observable<any> {
    const subject = new Subject<any>();
    const fs = this.getTotalPemakaianWtp3(startDate, endDate)
      .pipe(take(1))
      .subscribe((next) => {
        //// console.log(next);
        if (!isEmpty(next)) {
          const tbl = this.generateTable(next, [
            wtp3BakuId,
            wtp3Industri,
            wtp3Carenang,
            wtp3Cijeruk,
            wtp3R2r3ke1,
          ]);
          //// console.log(tbl);
          const totalPemakaian = this.pemakaianWTP3(tbl);

          const totBaku = !isNaN(
            totalPemakaian[totalPemakaian.length - 1]["totalBaku"]
          )
            ? totalPemakaian[totalPemakaian.length - 1]["totalBaku"]
            : 0;
          const totIndustri = !isNaN(
            totalPemakaian[totalPemakaian.length - 1]["totalIndustri"]
          )
            ? totalPemakaian[totalPemakaian.length - 1]["totalIndustri"]
            : 0;
          const totCarenang = !isNaN(
            totalPemakaian[totalPemakaian.length - 1]["totalCarenang"]
          )
            ? totalPemakaian[totalPemakaian.length - 1]["totalCarenang"]
            : 0;
          const totCijeruk = !isNaN(
            totalPemakaian[totalPemakaian.length - 1]["totalCijeruk"]
          )
            ? totalPemakaian[totalPemakaian.length - 1]["totalCijeruk"]
            : 0;
          const totR2R = !isNaN(
            totalPemakaian[totalPemakaian.length - 1]["totalR2R3ke1"]
          )
            ? totalPemakaian[totalPemakaian.length - 1]["totalR2R3ke1"]
            : 0;
          //console.log('dibawah tot baku');
          const gw = this.groupingWtp3(totalPemakaian, groupName);
          //console.log(gw);
          const hsl = {
            baku: totBaku,
            industri: totIndustri,
            carenang: totCarenang,
            cijeruk: totCijeruk,
            r2r3ke1: totR2R,
            details: gw,
          };
          //console.log(hsl);
          subject.next(hsl);
        } else {
          subject.next({
            baku: 0,
            industri: 0,
            carenang: 0,
            cijeruk: 0,
            r2r3ke1: 0,
            details: null,
          });
        }
        fs.unsubscribe();
      });
    return subject.asObservable();
  }

  getTotalPemakaianWtp3(startDate, endDate): Observable<any[]> {
    return this._afs
      .collection<any>(this.dbTaskReports, (ref) =>
        ref
          .where("pelaksana", "==", "operator")
          .where("location", "==", "wtp 3")
          .where("forDate", ">=", Number(startDate))
          .where("forDate", "<=", Number(endDate))
      )
      .valueChanges({ idField: "id" });
  }
  // end ==========================================  total pemakaian wtp 1 =============================

  getSingleRow(
    location: string,
    startDate: number,
    endDate,
    first = true
  ): Observable<any[]> {
    return this._afs
      .collection<any>(this.dbTaskReports, (ref) =>
        ref
          .where("pelaksana", "==", "operator")
          .where("location", "==", location)
          .where("forDate", ">=", Number(startDate))
          .where("forDate", "<=", Number(endDate))
          .orderBy("forDate", first ? "asc" : "desc")
          .limit(1)
      )
      .valueChanges({ idField: "id" });
  }

  generateTable(rec: any, taskIdAsCol: any, withPhoto = false): any {
    const source = [];
    rec.forEach((td, index) => {
      let colTemp: any = [];
      let hasError: number = 0;
      colTemp = { ...colTemp, ...{ ["c-no"]: index + 1 } };
      colTemp = {
        ...colTemp,
        ...{
          ["tanggal"]: moment(td.forDate.toString().slice(0, 8), "YYYYMMDD")
            .format("YYYY-MM-DD")
            .toString(),
        },
      };
      colTemp = {
        ...colTemp,
        ...{ ["a-jam"]: td.forDate.toString().slice(8) },
      };
      colTemp = { ...colTemp, ...{ ["forDate"]: td.forDate } };
      colTemp = {
        ...colTemp,
        ...{ ["month"]: Number(td.forDate.toString().substr(0, 6)) },
      };
      colTemp = {
        ...colTemp,
        ...{ ["year"]: Number(td.forDate.toString().substr(0, 4)) },
      };

      taskIdAsCol.forEach((ti) => {
        if (td[ti]) {
          td[ti].details.forEach((el) => {
            if (withPhoto) {
              colTemp = { ...colTemp, ...{ [el.id + "-" + ti]: el.value } }; //input value tiap kolom
              if (el.name.toLowerCase() === "error" && hasError === 0) {
                hasError = 1;
              }
            } else {
              if (el.type !== "photo" && el.type !== "multiphoto") {
                colTemp = { ...colTemp, ...{ [el.id + "-" + ti]: el.value } }; //input value tiap kolom
              }
            }
          });
          if (withPhoto) {
            td[ti].photoDetails.forEach((el) => {
              let vl = "";
              if (el.value) {
                vl = el.value.map((v) => v.path).toString();
              }
              colTemp = { ...colTemp, ...{ [el.id + "-" + ti]: vl } }; //input value tiap kolom
            });
          }
        }
      });
      colTemp = { ...colTemp, ...{ ["error"]: hasError } };
      source.push(colTemp);
    });
    return source;
  }
  generateTableNumericOnly(rec: any, taskIdAsCol: any): any {
    const source = [];
    rec.forEach((td, index) => {
      let colTemp: any = [];
      colTemp = { ...colTemp, ...{ ["c-no"]: index + 1 } };
      colTemp = {
        ...colTemp,
        ...{
          ["tanggal"]: moment(td.forDate.toString().slice(0, 8), "YYYYMMDD")
            .format("YYYY-MM-DD")
            .toString(),
        },
      };
      colTemp = {
        ...colTemp,
        ...{ ["a-jam"]: td.forDate.toString().slice(8) },
      };
      colTemp = { ...colTemp, ...{ ["location"]: td.location } };
      colTemp = {
        ...colTemp,
        ...{ ["month"]: Number(td.forDate.toString().substr(0, 6)) },
      };
      colTemp = {
        ...colTemp,
        ...{ ["year"]: Number(td.forDate.toString().substr(0, 4)) },
      };

      taskIdAsCol.forEach((ti) => {
        if (td[ti]) {
          td[ti].details.forEach((el) => {
            if (el.type !== "photo" && el.type !== "multiphoto") {
              colTemp = {
                ...colTemp,
                ...{ [el.id + "-" + ti]: Number(el.value) },
              }; //input value tiap kolom
            }
          });
        }
      });
      source.push(colTemp);
    });
    return source;
  }
  pemakaianKimiaWtp1(sourceAwal): any {
    //ambild dari selisih tangki
    let totPac = 0;
    let totKap = 0;
    sourceAwal.forEach((el, idx) => {
      let prevPac = 0;
      let prevKap = 0;
      if (idx > 0) {
        prevPac = !isNaN(sourceAwal[idx - 1][keyPac])
          ? sourceAwal[idx - 1][keyPac]
          : 0;
        prevKap = !isNaN(sourceAwal[idx - 1][keyKap])
          ? sourceAwal[idx - 1][keyKap]
          : 0;
      } else {
        sourceAwal[idx]["totalPac"] = 0;
        sourceAwal[idx]["totalKap"] = 0;
      }
      let hslPac = Number(
        prevPac === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyPac])
          ? prevPac - sourceAwal[idx][keyPac]
          : 0
      );
      let hslKap = Number(
        prevKap === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyKap])
          ? prevKap - sourceAwal[idx][keyKap]
          : 0
      );

      hslPac = Number(hslPac < 0 ? 0 : hslPac); //jika minus berarti flowmeter di reset
      hslKap = Number(hslKap < 0 ? 0 : hslKap);
      sourceAwal[idx][keyPacPmk] = Number(hslPac);
      sourceAwal[idx][keyKapPmk] = Number(hslKap);
      totPac += Number(hslPac);
      totKap += Number(hslKap);
      sourceAwal[idx]["pmkPac"] = hslPac;
      sourceAwal[idx]["pmkKap"] = hslKap;
      sourceAwal[idx]["totalPac"] = totPac;
      sourceAwal[idx]["totalKap"] = totKap;
    });

    return this.cleanArray(sourceAwal);
  }

  pemakaianKimiaWtp3(sourceAwal): any {
    //ambild dari selisih tangki
    let totPacA = 0;
    let totPacB = 0;
    let totKapA = 0;
    let totKapB = 0;
    let totPolA = 0;
    let totPolB = 0;
    sourceAwal.forEach((el, idx) => {
      let prevPacA = 0;
      let prevPacB = 0;
      let prevKapA = 0;
      let prevKapB = 0;
      let prevPolA = 0;
      let prevPolB = 0;
      if (idx > 0) {
        prevPacA = !isNaN(sourceAwal[idx - 1][keyPacA])
          ? sourceAwal[idx - 1][keyPacA]
          : 0;
        prevPacB = !isNaN(sourceAwal[idx - 1][keyPacB])
          ? sourceAwal[idx - 1][keyPacB]
          : 0;
        prevKapA = !isNaN(sourceAwal[idx - 1][keyKapA])
          ? sourceAwal[idx - 1][keyKapA]
          : 0;
        prevKapB = !isNaN(sourceAwal[idx - 1][keyKapB])
          ? sourceAwal[idx - 1][keyKapB]
          : 0;
        prevPolA = !isNaN(sourceAwal[idx - 1][keyPolA])
          ? sourceAwal[idx - 1][keyPolA]
          : 0;
        prevPolB = !isNaN(sourceAwal[idx - 1][keyPolB])
          ? sourceAwal[idx - 1][keyPolB]
          : 0;
      } else {
        sourceAwal[idx]["totalPacA"] = 0;
        sourceAwal[idx]["totalPacB"] = 0;
        sourceAwal[idx]["totalKapA"] = 0;
        sourceAwal[idx]["totalKapB"] = 0;
        sourceAwal[idx]["totalPolA"] = 0;
        sourceAwal[idx]["totalPolB"] = 0;
      }
      let hslPacA = Number(
        prevPacA === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyPacA])
          ? prevPacA - sourceAwal[idx][keyPacA]
          : 0
      );
      let hslPacB = Number(
        prevPacB === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyPacB])
          ? prevPacB - sourceAwal[idx][keyPacB]
          : 0
      );
      let hslKapA = Number(
        prevKapA === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyKapA])
          ? prevKapA - sourceAwal[idx][keyKapA]
          : 0
      );
      let hslKapB = Number(
        prevKapB === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyKapB])
          ? prevKapB - sourceAwal[idx][keyKapB]
          : 0
      );
      let hslPolA = Number(
        prevPolA === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyPolA])
          ? prevPolA - sourceAwal[idx][keyPolA]
          : 0
      );
      let hslPolB = Number(
        prevPolB === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyPolB])
          ? prevPolB - sourceAwal[idx][keyPolB]
          : 0
      );

      hslPacA = Number(hslPacA < 0 ? 0 : hslPacA); //jika minus berarti flowmeter di reset
      hslPacB = Number(hslPacB < 0 ? 0 : hslPacB);
      hslKapA = Number(hslKapA < 0 ? 0 : hslKapA);
      hslKapB = Number(hslKapB < 0 ? 0 : hslKapB);
      hslPolA = Number(hslPolA < 0 ? 0 : hslPolA);
      hslPolB = Number(hslPolB < 0 ? 0 : hslPolB);

      sourceAwal[idx][keyPacAPmk] = Number(hslPacA);
      sourceAwal[idx][keyPacBPmk] = Number(hslPacB);
      sourceAwal[idx][keyKapAPmk] = Number(hslKapA);
      sourceAwal[idx][keyKapBPmk] = Number(hslKapB);
      sourceAwal[idx][keyPolAPmk] = Number(hslPolA);
      sourceAwal[idx][keyPolBPmk] = Number(hslPolB);

      totPacA += Number(hslPacA);
      totPacB += Number(hslPacB);
      totKapA += Number(hslKapA);
      totKapB += Number(hslKapB);
      totPolA += Number(hslPolA);
      totPolB += Number(hslPolB);

      sourceAwal[idx]["pmkPacA"] = hslPacA;
      sourceAwal[idx]["pmkPacB"] = hslPacB;
      sourceAwal[idx]["pmkKapA"] = hslKapA;
      sourceAwal[idx]["pmkKapB"] = hslKapB;
      sourceAwal[idx]["pmkPolA"] = hslPolA;
      sourceAwal[idx]["pmkPolB"] = hslPolB;
      sourceAwal[idx]["totalPacA"] = totPacA;
      sourceAwal[idx]["totalPacB"] = totPacB;
      sourceAwal[idx]["totalKapA"] = totKapA;
      sourceAwal[idx]["totalKapB"] = totKapB;
      sourceAwal[idx]["totalPolA"] = totPolA;
      sourceAwal[idx]["totalPolB"] = totPolB;
    });

    return this.cleanArray(sourceAwal);
  }

  pemakaianMeterKimia(sourceAwal): any {
    let totPac = 0;
    let totKap = 0;
    let totPolA = 0;
    let totPolB = 0;
    sourceAwal.forEach((el, idx) => {
      let prevPac = 0;
      let prevKap = 0;
      let prevPolA = 0;
      let prevPolB = 0;
      if (idx > 0) {
        prevPac = !isNaN(sourceAwal[idx - 1][keyMeterPac])
          ? sourceAwal[idx - 1][keyMeterPac]
          : 0;
        prevKap = !isNaN(sourceAwal[idx - 1][keyMeterKap])
          ? sourceAwal[idx - 1][keyMeterKap]
          : 0;
        prevPolA = !isNaN(sourceAwal[idx - 1][keyPolA])
          ? sourceAwal[idx - 1][keyPolA]
          : 0;
        prevPolB = !isNaN(sourceAwal[idx - 1][keyPolB])
          ? sourceAwal[idx - 1][keyPolB]
          : 0;
      } else {
        sourceAwal[idx]["totalPac"] = 0;
        sourceAwal[idx]["totalKap"] = 0;
        sourceAwal[idx]["totalPolA"] = 0;
        sourceAwal[idx]["totalPolB"] = 0;
      }
      let hslPac = Number(
        prevPac === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyMeterPac])
          ? sourceAwal[idx][keyMeterPac] - prevPac
          : 0
      );
      let hslKap = Number(
        prevKap === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyMeterKap])
          ? sourceAwal[idx][keyMeterKap] - prevKap
          : 0
      );
      let hslPolA = Number(
        prevPolA === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyPolA])
          ? prevPolA - sourceAwal[idx][keyPolA]
          : 0
      );
      let hslPolB = Number(
        prevPolB === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyPolB])
          ? prevPolB - sourceAwal[idx][keyPolB]
          : 0
      );

      if (hslPac < 0) {
        if (
          sourceAwal[idx][keyMeterPacPmk] ||
          sourceAwal[idx][keyMeterPacPmk] !== "0"
        ) {
          hslPac = Number(sourceAwal[idx][keyMeterPacPmk]);
        } else {
          hslPac = Number(hslPac < 0 ? 0 : hslPac);
        }
      }

      if (hslKap < 0) {
        if (
          sourceAwal[idx][keyMeterKapPmk] ||
          sourceAwal[idx][keyMeterKapPmk] !== "0"
        ) {
          hslKap = Number(sourceAwal[idx][keyMeterKapPmk]);
        } else {
          hslKap = Number(hslKap < 0 ? 0 : hslKap);
        }
      }
      hslPolA = Number(hslPolA < 0 ? 0 : hslPolA);
      hslPolB = Number(hslPolB < 0 ? 0 : hslPolB);
      hslPac = Number(hslPac < 0 ? sourceAwal[idx][keyMeterPac] : hslPac); //jika minus berarti flowmeter di reset
      hslKap = Number(hslKap < 0 ? sourceAwal[idx][keyMeterKap] : hslKap);

      sourceAwal[idx][keyMeterPacPmk] = hslPac;
      sourceAwal[idx][keyMeterKapPmk] = hslKap;
      sourceAwal[idx][keyPolAPmk] = Number(hslPolA);
      sourceAwal[idx][keyPolBPmk] = Number(hslPolB);

      totPac += Number(hslPac);
      totKap += Number(hslKap);
      totPolA += Number(hslPolA);
      totPolB += Number(hslPolB);

      sourceAwal[idx]["pmkPac"] = hslPac;
      sourceAwal[idx]["pmkKap"] = hslKap;
      sourceAwal[idx]["pmkPolA"] = hslPolA;
      sourceAwal[idx]["pmkPolB"] = hslPolB;

      sourceAwal[idx]["totalPac"] = totPac;
      sourceAwal[idx]["totalKap"] = totKap;
      sourceAwal[idx]["totalPolA"] = totPolA;
      sourceAwal[idx]["totalPolB"] = totPolB;
    });

    return this.cleanArray(sourceAwal);
  }
  pemakaianWTP1(sourceAwal): any {
    const keyBaku = "16345227100020-PoieD3yxn4avq9SrEp18";
    const keyBakuPmk = "16361722110851-PoieD3yxn4avq9SrEp18";
    const keyCikande = "16345228140830-g7cSe37ROXCVtVyopNyJ";
    const keyCikandePmk = "16361722787411-g7cSe37ROXCVtVyopNyJ";
    const keyR2R1ke3 = "16507533815760-" + wtp1R2r1ke3;
    const keyR2R1ke3Pmk = "16507533815761-" + wtp1R2r1ke3;

    const keyKwhTotalizer = "16340294230800-" + wtp1DayaKwh;
    const keyKwhTotalizerPmk = "16340294230801-" + wtp1DayaKwh;
    const keyKwhBp = "16457081618852-" + wtp1DayaKwh;
    const keyKwhBpPmk = "16457081618853-" + wtp1DayaKwh;
    const keyKwhLbp = "16457081618854-" + wtp1DayaKwh;
    const keyKwhLbpPmk = "16457081618855-" + wtp1DayaKwh;
    const keyKwhKvar = "16457081618856-" + wtp1DayaKwh;
    const keyKwhKvarPmk = "16457081618857-" + wtp1DayaKwh;

    let totBaku = 0;
    let totCikande = 0;
    let totR2R = 0;
    let totKwhTotalizer = 0;
    let totKwhBp = 0;
    let totKwhLbp = 0;
    let totKwhKvar = 0;

    let lastBaku = 0; //nilai terakhir
    let lastCikande = 0;
    let lastR2R = 0;
    let lastKwhTotalizer = 0;
    let lastKwhBp = 0;
    let lastKwhLbp = 0;
    let lastKwhKvar = 0;

    sourceAwal.forEach((el, idx) => {
      let prevBaku = 0;
      let prevCikande = 0;
      let prevR2R = 0;
      let prevKwhTotalizer = 0;
      let prevKwhBp = 0;
      let prevKwhLbp = 0;
      let prevKwhKvar = 0;
      if (idx > 0) {
        //simpan last value yg != 0
        if (!isNaN(sourceAwal[idx - 1][keyBaku])) {
          lastBaku = sourceAwal[idx - 1][keyBaku];
        }
        if (!isNaN(sourceAwal[idx - 1][keyCikande])) {
          lastCikande = sourceAwal[idx - 1][keyCikande];
        }
        if (!isNaN(sourceAwal[idx - 1][keyR2R1ke3])) {
          lastR2R = sourceAwal[idx - 1][keyR2R1ke3];
        }
        if (!isNaN(sourceAwal[idx - 1][keyKwhTotalizer])) {
          lastKwhTotalizer = sourceAwal[idx - 1][keyKwhTotalizer];
        }
        if (!isNaN(sourceAwal[idx - 1][keyKwhBp])) {
          lastKwhBp = sourceAwal[idx - 1][keyKwhBp];
        }
        if (!isNaN(sourceAwal[idx - 1][keyKwhLbp])) {
          lastKwhLbp = sourceAwal[idx - 1][keyKwhLbp];
        }
        if (!isNaN(sourceAwal[idx - 1][keyKwhKvar])) {
          lastKwhKvar = sourceAwal[idx - 1][keyKwhKvar];
        }

        prevBaku = !isNaN(sourceAwal[idx - 1][keyBaku])
          ? sourceAwal[idx - 1][keyBaku]
          : lastBaku;
        prevCikande = !isNaN(sourceAwal[idx - 1][keyCikande])
          ? sourceAwal[idx - 1][keyCikande]
          : lastCikande;
        prevR2R = !isNaN(sourceAwal[idx - 1][keyR2R1ke3])
          ? sourceAwal[idx - 1][keyR2R1ke3]
          : lastR2R;
        prevKwhTotalizer = !isNaN(sourceAwal[idx - 1][keyKwhTotalizer])
          ? sourceAwal[idx - 1][keyKwhTotalizer]
          : lastKwhTotalizer;
        prevKwhBp = !isNaN(sourceAwal[idx - 1][keyKwhBp])
          ? sourceAwal[idx - 1][keyKwhBp]
          : lastKwhBp;
        prevKwhLbp = !isNaN(sourceAwal[idx - 1][keyKwhLbp])
          ? sourceAwal[idx - 1][keyKwhLbp]
          : lastKwhLbp;
        prevKwhKvar = !isNaN(sourceAwal[idx - 1][keyKwhKvar])
          ? sourceAwal[idx - 1][keyKwhKvar]
          : lastKwhKvar;
      } else {
        sourceAwal[idx]["totalBaku"] = 0;
        sourceAwal[idx]["totalCikande"] = 0;
        sourceAwal[idx]["totalR2R1ke3"] = 0;
        sourceAwal[idx]["totalKwhTotalizer"] = 0;
        sourceAwal[idx]["totalKwhBp"] = 0;
        sourceAwal[idx]["totalKwhLbp"] = 0;
        sourceAwal[idx]["totalKwhKvar"] = 0;
      }
      let hslBaku = Number(
        prevBaku === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyBaku])
          ? sourceAwal[idx][keyBaku] - prevBaku
          : 0
      );
      let hslCikande = Number(
        prevCikande === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyCikande])
          ? sourceAwal[idx][keyCikande] - prevCikande
          : 0
      );
      let hslR2R = Number(
        prevR2R === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyR2R1ke3])
          ? sourceAwal[idx][keyR2R1ke3] - prevR2R
          : 0
      );
      let hslKwhTotalizer = Number(
        prevKwhTotalizer === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyKwhTotalizer])
          ? sourceAwal[idx][keyKwhTotalizer] - prevKwhTotalizer
          : 0
      );
      let hslKwhBp = Number(
        prevKwhBp === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyKwhBp])
          ? sourceAwal[idx][keyKwhBp] - prevKwhBp
          : 0
      );
      let hslKwhLbp = Number(
        prevKwhLbp === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyKwhLbp])
          ? sourceAwal[idx][keyKwhLbp] - prevKwhLbp
          : 0
      );
      let hslKwhKvar = Number(
        prevKwhKvar === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyKwhKvar])
          ? sourceAwal[idx][keyKwhKvar] - prevKwhKvar
          : 0
      );

      if (hslBaku < 0) {
        if (
          sourceAwal[idx][keyBakuPmk] ||
          sourceAwal[idx][keyBakuPmk] !== "0"
        ) {
          hslBaku = Number(sourceAwal[idx][keyBakuPmk]);
        } else {
          hslBaku = Number(hslBaku < 0 ? 0 : hslBaku);
        }
      }
      if (hslCikande < 0) {
        if (
          sourceAwal[idx][keyCikandePmk] ||
          sourceAwal[idx][keyCikandePmk] !== "0"
        ) {
          hslCikande = Number(sourceAwal[idx][keyCikandePmk]);
        } else {
          hslCikande = Number(hslCikande < 0 ? 0 : hslCikande);
        }
      }
      if (hslR2R < 0) {
        if (
          sourceAwal[idx][keyR2R1ke3Pmk] ||
          sourceAwal[idx][keyR2R1ke3Pmk] !== "0"
        ) {
          hslR2R = Number(sourceAwal[idx][keyR2R1ke3Pmk]);
        } else {
          hslR2R = Number(hslR2R < 0 ? 0 : hslR2R);
        }
      }
      if (hslKwhTotalizer < 0) {
        if (
          sourceAwal[idx][keyKwhTotalizerPmk] ||
          sourceAwal[idx][keyKwhTotalizerPmk] !== "0"
        ) {
          hslKwhTotalizer = Number(sourceAwal[idx][keyKwhTotalizerPmk]);
        } else {
          hslKwhTotalizer = Number(hslKwhTotalizer < 0 ? 0 : hslKwhTotalizer);
        }
      }
      if (hslKwhBp < 0) {
        if (
          sourceAwal[idx][keyKwhBpPmk] ||
          sourceAwal[idx][keyKwhBpPmk] !== "0"
        ) {
          hslKwhBp = Number(sourceAwal[idx][keyKwhBpPmk]);
        } else {
          hslKwhBp = Number(hslKwhBp < 0 ? 0 : hslKwhBp);
        }
      }
      if (hslKwhLbp < 0) {
        if (
          sourceAwal[idx][keyKwhLbpPmk] ||
          sourceAwal[idx][keyKwhLbpPmk] !== "0"
        ) {
          hslKwhLbp = Number(sourceAwal[idx][keyKwhLbpPmk]);
        } else {
          hslKwhLbp = Number(hslKwhLbp < 0 ? 0 : hslKwhLbp);
        }
      }
      if (hslKwhKvar < 0) {
        if (
          sourceAwal[idx][keyKwhKvarPmk] ||
          sourceAwal[idx][keyKwhKvarPmk] !== "0"
        ) {
          hslKwhKvar = Number(sourceAwal[idx][keyKwhKvarPmk]);
        } else {
          hslKwhKvar = Number(hslKwhKvar < 0 ? 0 : hslKwhKvar);
        }
      }

      hslBaku = round(
        Number(hslBaku < 0 ? sourceAwal[idx][keyBaku] : hslBaku),
        2
      ); //jika minus berarti flowmeter di reset
      hslCikande = round(
        Number(hslCikande < 0 ? sourceAwal[idx][keyCikande] : hslCikande),
        2
      );
      hslR2R = round(
        Number(hslR2R < 0 ? sourceAwal[idx][keyR2R1ke3] : hslR2R),
        2
      );
      hslKwhTotalizer = round(
        Number(
          hslKwhTotalizer < 0
            ? sourceAwal[idx][keyKwhTotalizer]
            : hslKwhTotalizer
        ),
        2
      );
      hslKwhBp = round(
        Number(hslKwhBp < 0 ? sourceAwal[idx][keyKwhBp] : hslKwhBp),
        2
      );
      hslKwhLbp = round(
        Number(hslKwhLbp < 0 ? sourceAwal[idx][keyKwhLbp] : hslKwhLbp),
        2
      );
      hslKwhKvar = round(
        Number(hslKwhKvar < 0 ? sourceAwal[idx][keyKwhKvar] : hslKwhKvar),
        2
      );

      sourceAwal[idx][keyBakuPmk] = hslBaku;
      sourceAwal[idx][keyCikandePmk] = hslCikande;
      sourceAwal[idx][keyR2R1ke3Pmk] = hslR2R;
      sourceAwal[idx][keyKwhTotalizerPmk] = hslKwhTotalizer;
      sourceAwal[idx][keyKwhBpPmk] = hslKwhBp;
      sourceAwal[idx][keyKwhLbpPmk] = hslKwhLbp;
      sourceAwal[idx][keyKwhKvarPmk] = hslKwhKvar;

      totBaku += Number(hslBaku);
      totCikande += Number(hslCikande);
      totR2R += Number(hslR2R);
      totKwhTotalizer += Number(hslKwhTotalizer);
      totKwhBp += Number(hslKwhBp);
      totKwhLbp += Number(hslKwhLbp);
      totKwhKvar += Number(hslKwhKvar);

      sourceAwal[idx]["pmkBaku"] = hslBaku;
      sourceAwal[idx]["pmkCikande"] = hslCikande;
      sourceAwal[idx]["pmkR2R1ke3"] = hslR2R;
      sourceAwal[idx]["pmkKwhTotalizer"] = hslKwhTotalizer;
      sourceAwal[idx]["pmkKwhBp"] = hslKwhBp;
      sourceAwal[idx]["pmkKwhLbp"] = hslKwhLbp;
      sourceAwal[idx]["pmkKwhKvar"] = hslKwhKvar;

      sourceAwal[idx]["totalBaku"] = totBaku;
      sourceAwal[idx]["totalCikande"] = totCikande;
      sourceAwal[idx]["totalR2R1ke3"] = totR2R;
      sourceAwal[idx]["totalKwhTotalizer"] = totKwhTotalizer;
      sourceAwal[idx]["totalKwhBp"] = totKwhBp;
      sourceAwal[idx]["totalKwhLbp"] = totKwhLbp;
      sourceAwal[idx]["totalKwhKvar"] = totKwhKvar;
    });

    return this.cleanArray(sourceAwal);
  }

  pemakaianWTP2(sourceAwal): any {
    const keyBaku = "16507531147060-" + wtp2BakuId;
    const keyBakuPmk = "16507531147061-" + wtp2BakuId;
    const keyProduksi = "16507558950130-" + wtp2Produksi;
    const keyProduksiPmk = "16507558950131-" + wtp2Produksi;

    let totBaku = 0;
    let totProduksi = 0;
    sourceAwal.forEach((el, idx) => {
      let prevBaku = 0;
      let prevProduksi = 0;
      if (idx > 0) {
        prevBaku = !isNaN(sourceAwal[idx - 1][keyBaku])
          ? sourceAwal[idx - 1][keyBaku]
          : 0;
        prevProduksi = !isNaN(sourceAwal[idx - 1][keyProduksi])
          ? sourceAwal[idx - 1][keyProduksi]
          : 0;
      } else {
        sourceAwal[idx]["totalBaku"] = 0;
        sourceAwal[idx]["totalProduksi"] = 0;
      }
      let hslBaku = Number(
        prevBaku === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyBaku])
          ? sourceAwal[idx][keyBaku] - prevBaku
          : 0
      );
      let hslProduksi = Number(
        prevProduksi === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyProduksi])
          ? sourceAwal[idx][keyProduksi] - prevProduksi
          : 0
      );

      if (hslBaku < 0) {
        if (
          sourceAwal[idx][keyBakuPmk] ||
          sourceAwal[idx][keyBakuPmk] !== "0"
        ) {
          hslBaku = Number(sourceAwal[idx][keyBakuPmk]);
        } else {
          hslBaku = Number(hslBaku < 0 ? 0 : hslBaku);
        }
      }

      if (hslProduksi < 0) {
        if (
          sourceAwal[idx][keyProduksiPmk] ||
          sourceAwal[idx][keyProduksiPmk] !== "0"
        ) {
          hslProduksi = Number(sourceAwal[idx][keyProduksiPmk]);
        } else {
          hslProduksi = Number(hslProduksi < 0 ? 0 : hslProduksi);
        }
      }

      hslBaku = Number(hslBaku < 0 ? sourceAwal[idx][keyBaku] : hslBaku); //jika minus berarti flowmeter di reset
      hslProduksi = Number(
        hslProduksi < 0 ? sourceAwal[idx][keyProduksi] : hslProduksi
      );
      sourceAwal[idx][keyBakuPmk] = hslBaku;
      sourceAwal[idx][keyProduksiPmk] = hslProduksi;
      totBaku += Number(hslBaku);
      totProduksi += Number(hslProduksi);
      sourceAwal[idx]["pmkBaku"] = hslBaku;
      sourceAwal[idx]["pmkProduksi"] = hslProduksi;
      sourceAwal[idx]["totalBaku"] = totBaku;
      sourceAwal[idx]["totalProduksi"] = totProduksi;
    });

    return this.cleanArray(sourceAwal);
  }

  pemakaianWTP3(sourceAwal): any {
    const keyBaku = "16340271556860-n2adtBK2C8hoGio0RJnu";
    const keyBakuPemakain = "16340271556861-n2adtBK2C8hoGio0RJnu";
    const keyIndustri = "16340273727770-TJFeQKrXcHNtSoTcahfg";
    const keyIndustriPemakaian = "16340273727771-TJFeQKrXcHNtSoTcahfg";
    const keyCarenang = "16340282393690-JOFQowzTotL69WsY12W5";
    const keyCarenangPemakaian = "16340282393691-JOFQowzTotL69WsY12W5";
    const keyCijeruk = "16340283218210-yUe74oUfCtWXiK4HU5BK";
    const keyCijerukPemakaian = "16340283218211-yUe74oUfCtWXiK4HU5BK";
    const keyR2R3ke1 = "16507535233910-" + wtp3R2r3ke1;
    const keyR2R3ke1Pemakaian = "16507535233911-" + wtp3R2r3ke1;

    // pemakaian meteran di dari meter sekarang - meter sebelumnya
    let totBaku = 0;
    let totIndustri = 0;
    let totCarenang = 0;
    let totCijeruk = 0;
    let totR2R = 0;
    // pemakaian kimia di dari meter sebelumnya - meter sekarang
    let totPolA = 0;
    let totPolB = 0;
    let totPacA = 0;
    let totPacB = 0;
    let totKapA = 0;
    let totKapB = 0;
    // flow meter kimia
    let totMeterPac = 0;
    let totMeterKap = 0;

    let lastBaku = 0;
    let lastIndustri = 0;
    let lastCarenang = 0;
    let lastCijeruk = 0;
    // let lastR2R = 0;
    let lastMeterPac = 0;
    let lastMeterKap = 0;

    sourceAwal.forEach((el, idx) => {
      let prevBaku = 0;
      let prevIndustri = 0;
      let prevCarenang = 0;
      let prevCijeruk = 0;
      let prevR2R = 0;
      let prevPolA = 0;
      let prevPolB = 0;
      let prevPacA = 0;
      let prevPacB = 0;
      let prevKapA = 0;
      let prevKapB = 0;
      let prevMeterPac = 0;
      let prevMeterKap = 0;
      if (idx > 0) {
        //simpan last value yg != 0
        if (!isNaN(sourceAwal[idx - 1][keyBaku])) {
          lastBaku = sourceAwal[idx - 1][keyBaku];
        }
        if (!isNaN(sourceAwal[idx - 1][keyIndustri])) {
          lastIndustri = sourceAwal[idx - 1][keyIndustri];
        }
        if (!isNaN(sourceAwal[idx - 1][keyCarenang])) {
          lastCarenang = sourceAwal[idx - 1][keyCarenang];
        }
        if (!isNaN(sourceAwal[idx - 1][keyCijeruk])) {
          lastCijeruk = sourceAwal[idx - 1][keyCijeruk];
        }
        // if(!isNaN(sourceAwal[idx-1][keyR2R3ke1])){
        //     lastR2R = sourceAwal[idx-1][keyR2R3ke1];
        // }
        if (!isNaN(sourceAwal[idx - 1][keyMeterPac])) {
          lastMeterPac = sourceAwal[idx - 1][keyMeterPac];
        }
        if (!isNaN(sourceAwal[idx - 1][keyMeterKap])) {
          lastMeterKap = sourceAwal[idx - 1][keyMeterKap];
        }
        prevBaku = !isNaN(sourceAwal[idx - 1][keyBaku])
          ? sourceAwal[idx - 1][keyBaku]
          : lastBaku;
        prevIndustri = !isNaN(sourceAwal[idx - 1][keyIndustri])
          ? sourceAwal[idx - 1][keyIndustri]
          : lastIndustri;
        prevCarenang = !isNaN(sourceAwal[idx - 1][keyCarenang])
          ? sourceAwal[idx - 1][keyCarenang]
          : lastCarenang;
        prevCijeruk = !isNaN(sourceAwal[idx - 1][keyCijeruk])
          ? sourceAwal[idx - 1][keyCijeruk]
          : lastCijeruk;
        prevR2R = !isNaN(sourceAwal[idx - 1][keyR2R3ke1])
          ? sourceAwal[idx - 1][keyR2R3ke1]
          : 0;

        prevPolA = !isNaN(sourceAwal[idx - 1][keyPolA])
          ? sourceAwal[idx - 1][keyPolA]
          : 0;
        prevPolB = !isNaN(sourceAwal[idx - 1][keyPolB])
          ? sourceAwal[idx - 1][keyPolB]
          : 0;
        prevPacA = !isNaN(sourceAwal[idx - 1][keyPacA])
          ? sourceAwal[idx - 1][keyPacA]
          : 0;
        prevPacB = !isNaN(sourceAwal[idx - 1][keyPacB])
          ? sourceAwal[idx - 1][keyPacB]
          : 0;
        prevKapA = !isNaN(sourceAwal[idx - 1][keyKapA])
          ? sourceAwal[idx - 1][keyKapA]
          : 0;
        prevKapB = !isNaN(sourceAwal[idx - 1][keyKapB])
          ? sourceAwal[idx - 1][keyKapB]
          : 0;
        prevMeterPac = !isNaN(sourceAwal[idx - 1][keyMeterPac])
          ? sourceAwal[idx - 1][keyMeterPac]
          : lastMeterPac;
        prevMeterKap = !isNaN(sourceAwal[idx - 1][keyMeterKap])
          ? sourceAwal[idx - 1][keyMeterKap]
          : lastMeterKap;
      }

      // let hslBaku = Number(prevBaku===0?0:sourceAwal[idx][keyBaku] - prevBaku);
      let hslBaku = Number(
        prevBaku === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyBaku])
          ? sourceAwal[idx][keyBaku] - prevBaku
          : 0
      );
      let hslIndustri = Number(
        prevIndustri === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyIndustri])
          ? sourceAwal[idx][keyIndustri] - prevIndustri
          : 0
      );
      let hslCarenang = Number(
        prevCarenang === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyCarenang])
          ? sourceAwal[idx][keyCarenang] - prevCarenang
          : 0
      );
      let hslCijeruk = Number(
        prevCijeruk === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyCijeruk])
          ? sourceAwal[idx][keyCijeruk] - prevCijeruk
          : 0
      );
      let hslR2R = Number(
        prevR2R === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyR2R3ke1])
          ? sourceAwal[idx][keyR2R3ke1] - prevR2R
          : 0
      );

      let hslPolA = Number(
        prevPolA === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyPolA])
          ? prevPolA - sourceAwal[idx][keyPolA]
          : 0
      );
      let hslPolB = Number(
        prevPolB === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyPolB])
          ? prevPolB - sourceAwal[idx][keyPolB]
          : 0
      );
      let hslPacA = Number(
        prevPacA === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyPacA])
          ? prevPacA - sourceAwal[idx][keyPacA]
          : 0
      );
      let hslPacB = Number(
        prevPacB === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyPacB])
          ? prevPacB - sourceAwal[idx][keyPacB]
          : 0
      );
      let hslKapA = Number(
        prevKapA === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyKapA])
          ? prevKapA - sourceAwal[idx][keyKapA]
          : 0
      );
      let hslKapB = Number(
        prevKapB === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyKapB])
          ? prevKapB - sourceAwal[idx][keyKapB]
          : 0
      );

      let hslMeterPac = Number(
        prevMeterPac === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyMeterPac])
          ? sourceAwal[idx][keyMeterPac] - prevMeterPac
          : 0
      );
      let hslMeterKap = Number(
        prevMeterKap === 0
          ? 0
          : !isNaN(sourceAwal[idx][keyMeterKap])
          ? sourceAwal[idx][keyMeterKap] - prevMeterKap
          : 0
      );

      if (hslBaku < 0) {
        //cek apakah ada pemakaian yg di isi manual
        if (
          sourceAwal[idx][keyBakuPemakain] ||
          sourceAwal[idx][keyBakuPemakain] !== "0"
        ) {
          hslBaku = Number(sourceAwal[idx][keyBakuPemakain]);
        } else {
          hslBaku = Number(hslBaku < 0 ? 0 : hslBaku); //sourceAwal[idx][keyBaku]:hslBaku);
        }
      }
      if (hslIndustri < 0) {
        //cek apakah ada pemakaian yg di isi manual
        if (
          sourceAwal[idx][keyIndustriPemakaian] ||
          sourceAwal[idx][keyIndustriPemakaian] !== "0"
        ) {
          hslIndustri = Number(sourceAwal[idx][keyIndustriPemakaian]);
        } else {
          hslIndustri = Number(hslIndustri < 0 ? 0 : hslIndustri); //sourceAwal[idx][keyIndustri]:hslIndustri);
        }
      }
      if (hslCarenang < 0) {
        //cek apakah ada pemakaian yg di isi manual
        if (
          sourceAwal[idx][keyCarenangPemakaian] ||
          sourceAwal[idx][keyCarenangPemakaian] !== "0"
        ) {
          hslCarenang = Number(sourceAwal[idx][keyCarenangPemakaian]);
        } else {
          hslCarenang = Number(hslCarenang < 0 ? 0 : hslCarenang); //sourceAwal[idx][keyCarenang]:hslCarenang);
        }
      }
      if (hslCijeruk < 0) {
        //cek apakah ada pemakaian yg di isi manual
        if (
          sourceAwal[idx][keyCijerukPemakaian] ||
          sourceAwal[idx][keyCijerukPemakaian] !== "0"
        ) {
          hslCijeruk = Number(sourceAwal[idx][keyCijerukPemakaian]);
        } else {
          hslCijeruk = Number(hslCijeruk < 0 ? 0 : hslCijeruk); //sourceAwal[idx][keyCijeruk]:hslCijeruk);
        }
      }

      if (hslR2R < 0) {
        //cek apakah ada pemakaian yg di isi manual
        if (
          sourceAwal[idx][keyR2R3ke1Pemakaian] ||
          sourceAwal[idx][keyR2R3ke1Pemakaian] !== "0"
        ) {
          hslR2R = Number(sourceAwal[idx][keyR2R3ke1Pemakaian]);
        } else {
          hslR2R = Number(hslR2R < 0 ? 0 : hslR2R); //sourceAwal[idx][keyCijeruk]:hslCijeruk);
        }
      }

      if (hslPolA < 0) {
        //cek apakah ada pemakaian yg di isi manual
        if (
          sourceAwal[idx][keyPolAPmk] ||
          sourceAwal[idx][keyPolAPmk] !== "0"
        ) {
          hslPolA = Number(sourceAwal[idx][keyPolAPmk]);
        } else {
          hslPolA = Number(hslPolA < 0 ? 0 : hslPolA);
        }
      }
      if (hslPolB < 0) {
        //cek apakah ada pemakaian yg di isi manual
        if (
          sourceAwal[idx][keyPolBPmk] ||
          sourceAwal[idx][keyPolBPmk] !== "0"
        ) {
          hslPolB = Number(sourceAwal[idx][keyPolBPmk]);
        } else {
          hslPolB = Number(hslPolB < 0 ? 0 : hslPolB);
        }
      }

      if (hslPacA < 0) {
        //cek apakah ada pemakaian yg di isi manual
        if (
          sourceAwal[idx][keyPacAPmk] ||
          sourceAwal[idx][keyPacAPmk] !== "0"
        ) {
          hslPacA = Number(sourceAwal[idx][keyPacAPmk]);
        } else {
          hslPacA = Number(hslPacA < 0 ? 0 : hslPacA);
        }
      }
      if (hslPacB < 0) {
        //cek apakah ada pemakaian yg di isi manual
        if (
          sourceAwal[idx][keyPacBPmk] ||
          sourceAwal[idx][keyPacBPmk] !== "0"
        ) {
          hslPacB = Number(sourceAwal[idx][keyPacBPmk]);
        } else {
          hslPacB = Number(hslPacB < 0 ? 0 : hslPacB);
        }
      }

      if (hslKapA < 0) {
        //cek apakah ada pemakaian yg di isi manual
        if (
          sourceAwal[idx][keyKapAPmk] ||
          sourceAwal[idx][keyKapAPmk] !== "0"
        ) {
          hslKapA = Number(sourceAwal[idx][keyKapAPmk]);
        } else {
          hslKapA = Number(hslKapA < 0 ? 0 : hslKapA);
        }
      }
      if (hslKapB < 0) {
        //cek apakah ada pemakaian yg di isi manual
        if (
          sourceAwal[idx][keyKapBPmk] ||
          sourceAwal[idx][keyKapBPmk] !== "0"
        ) {
          hslKapB = Number(sourceAwal[idx][keyKapBPmk]);
        } else {
          hslKapB = Number(hslKapB < 0 ? 0 : hslKapB);
        }
      }

      if (hslMeterPac < 0) {
        //cek apakah ada pemakaian yg di isi manual
        if (
          sourceAwal[idx][keyMeterPacPmk] ||
          sourceAwal[idx][keyMeterPacPmk] !== "0"
        ) {
          hslMeterPac = Number(sourceAwal[idx][keyMeterPacPmk]);
        } else {
          hslMeterPac = Number(hslMeterPac < 0 ? 0 : hslMeterPac);
        }
      }
      if (hslMeterKap < 0) {
        //cek apakah ada pemakaian yg di isi manual
        if (
          sourceAwal[idx][keyMeterKapPmk] ||
          sourceAwal[idx][keyMeterKapPmk] !== "0"
        ) {
          hslMeterKap = Number(sourceAwal[idx][keyMeterKapPmk]);
        } else {
          hslMeterKap = Number(hslMeterKap < 0 ? 0 : hslMeterKap);
        }
      }

      sourceAwal[idx][keyBakuPemakain] = hslBaku;
      sourceAwal[idx][keyIndustriPemakaian] = hslIndustri;
      sourceAwal[idx][keyCarenangPemakaian] = hslCarenang;
      sourceAwal[idx][keyCijerukPemakaian] = hslCijeruk;
      sourceAwal[idx][keyR2R3ke1Pemakaian] = hslR2R;

      sourceAwal[idx][keyPolAPmk] = hslPolA;
      sourceAwal[idx][keyPolBPmk] = hslPolB;
      sourceAwal[idx][keyPacAPmk] = hslPacA;
      sourceAwal[idx][keyPacBPmk] = hslPacB;
      sourceAwal[idx][keyKapAPmk] = hslKapA;
      sourceAwal[idx][keyKapBPmk] = hslKapB;

      sourceAwal[idx][keyMeterPacPmk] = hslMeterPac;
      sourceAwal[idx][keyMeterKapPmk] = hslMeterKap;

      totBaku += Number(hslBaku);
      totIndustri += Number(hslIndustri);
      totCarenang += Number(hslCarenang);
      totCijeruk += Number(hslCijeruk);
      totR2R += Number(hslR2R);

      totPolA += Number(hslPolA);
      totPolB += Number(hslPolB);
      totPacA += Number(hslPacA);
      totPacB += Number(hslPacB);
      totKapA += Number(hslKapA);
      totKapB += Number(hslKapB);

      totMeterPac += Number(hslMeterPac);
      totMeterKap += Number(hslMeterKap);

      sourceAwal[idx]["pmkBakuWtp3"] = hslBaku;
      sourceAwal[idx]["pmkIndustri"] = hslIndustri;
      sourceAwal[idx]["pmkCarenang"] = hslCarenang;
      sourceAwal[idx]["pmkCijeruk"] = hslCijeruk;
      sourceAwal[idx]["pmkR2R3ke1"] = hslR2R;

      sourceAwal[idx]["pmkPolA"] = hslPolA;
      sourceAwal[idx]["pmkPolB"] = hslPolB;
      sourceAwal[idx]["pmkPacA"] = hslPacA;
      sourceAwal[idx]["pmkPacB"] = hslPacB;
      sourceAwal[idx]["pmkKapA"] = hslKapA;
      sourceAwal[idx]["pmkKapB"] = hslKapB;

      sourceAwal[idx]["pmkMeterPac"] = hslMeterPac;
      sourceAwal[idx]["pmkMeterKap"] = hslMeterKap;

      sourceAwal[idx]["totalBaku"] = totBaku;
      sourceAwal[idx]["totalIndustri"] = totIndustri;
      sourceAwal[idx]["totalCarenang"] = totCarenang;
      sourceAwal[idx]["totalCijeruk"] = totCijeruk;
      sourceAwal[idx]["totalR2R3ke1"] = totR2R;

      sourceAwal[idx]["totalPolA"] = totPolA;
      sourceAwal[idx]["totalPolB"] = totPolB;
      sourceAwal[idx]["totalPacA"] = totPacA;
      sourceAwal[idx]["totalPacB"] = totPacB;
      sourceAwal[idx]["totalKapA"] = totKapA;
      sourceAwal[idx]["totalKapB"] = totKapB;

      sourceAwal[idx]["totalMeterPac"] = totMeterPac;
      sourceAwal[idx]["totalMeterKap"] = totMeterKap;
    });

    return this.cleanArray(sourceAwal);
  }

  pemakaianCustomer(next: any, startMonth, realPmkCust = false): any {
    //dalam setahun
    const customers = _(next)
      .groupBy("customerId")
      .map((data, id) => ({
        customerId: id,
      }))
      .value();
    let customerSales = [];
    let totPj = 0;
    let totRp = 0;
    const salesByDate = [];
    customers.forEach((cs: any) => {
      const a = next.filter((c) => c.customerId === cs.customerId);
      let lastMeter = 0;
      let custPemakaian = 0;
      let firstRecord = true;
      let minKonsumsi = 0;
      let startMeter = 0;
      let finishMeter = 0;
      a.forEach((ah) => {
        if (!ah.customerName) {
          console.log(ah);
        }
        minKonsumsi = ah.minKonsumsi ? ah.minKonsumsi : 0;
        const col = _.keys(ah);
        let pmkBulanan = 0;
        const onlyNumber = col.filter((it) => !isNaN(Number(it)));
        const colAsc = onlyNumber;
        let lastDate = 0;
        if (onlyNumber) {
          onlyNumber.sort();
          onlyNumber.reverse();
          lastDate = Number(onlyNumber[0]);
        }
        colAsc.sort().forEach((c) => {
          if (!isNaN(Number(c))) {
            //jika number maka ambil value nya aja
            // if(ah[c]['m3']>0){
            //     console.log('pemakaina', a);
            // }
            if (
              (Number(ah["forDate"]) === Number(startMonth) && a.length > 1) ||
              firstRecord
            ) {
              lastMeter = Number(ah[c]["meter"]); //isi last meter dari last month
              startMeter = lastMeter;
            } else {
              if (Number(ah["forDate"]) !== Number(startMonth)) {
                let pmk =
                  lastMeter <= 0 ? 0 : Number(ah[c]["meter"]) - lastMeter;
                if (startMeter === 0 && pmk === 0) {
                  pmk = Number(ah[c]["meter"]);
                }
                if (pmk < 0) {
                  if (ah[c]["m3"] > 0) {
                    pmk = Number(ah[c]["m3"]);
                  }
                  /* else{
                                        pmk =  Number(pmk<0?0:pmk);
                                    } */
                }
                //khusus untuk sorini di bulan februari 2023, karna pas reset meteran lebih besar dari pada meteran sebelumnya
                if (
                  cs.customerId === "oTd7qC8Pa8AtsqxeVBLp" &&
                  Number(c) === 20230206
                ) {
                  pmk = 0;
                }
                if (
                  cs.customerId === "MbdymaJj0okejoTKcgoB" &&
                  Number(c) === 20230302
                ) {
                  //modern pigeon
                  pmk = 0;
                }
                if (
                  cs.customerId === "iSjGr6jVyDsgtsg8iM8Z" &&
                  Number(c) === 20230302
                ) {
                  //pt. Sepuluh Sumber Anugrah 2
                  pmk = 8;
                }
                if (
                  cs.customerId === "lNzMe3PaZ03hJPMd8MF9" &&
                  Number(c) === 20230306
                ) {
                  // samator
                  pmk = 521;
                }
                if (
                  cs.customerId === "ZOoWH9xU5wjkXCBJA2Qu" &&
                  Number(c) === 20230504
                ) {
                  // pt. sanfang
                  pmk = 231;
                }
                if (
                  cs.customerId === "TvVxK8FHkD2RuI0X1uWr" &&
                  Number(c) === 20231106
                ) {
                  // pt. berkah manis
                  pmk = 9294;
                }

                if (ah[c]["reset"] === 1) {
                  pmk = Number(ah[c]["m3"]);
                }
                // const lastMeterTest = lastMeter;
                totPj += Number(pmk);
                custPemakaian += Number(pmk);
                lastMeter = Number(ah[c]["meter"]);
                pmkBulanan += Number(pmk);
                finishMeter = lastMeter;

                const biaya =
                  Number(c) === lastDate
                    ? this.hitungBiaya(minKonsumsi, pmkBulanan, realPmkCust)
                    : 0;
                const arrData = {
                  id: cs.customerId + "-" + c, //id utk viewPemakaian
                  curMeter: lastMeter,
                  // 'lastMeter': lastMeterTest,
                  customerName: ah.customerName,
                  customerId: cs.customerId,
                  tanggal: c,
                  month: Number(c.toString().substring(0, 6)),
                  pemakaian: Number(pmk),
                  rupiah: biaya !== 0 ? biaya["total"] : 0,
                  rupiahSepuluh: biaya !== 0 ? biaya["sepuluh"] : 0,
                  rupiahTengah: biaya !== 0 ? biaya["tengah"] : 0,
                  rupiahDuapuluh: biaya !== 0 ? biaya["duapuluh"] : 0,
                  m3Sepuluh: biaya !== 0 ? biaya["m3Sepuluh"] : 0,
                  m3Tengah: biaya !== 0 ? biaya["m3Tengah"] : 0,
                  m3Duapuluh: biaya !== 0 ? biaya["m3Duapuluh"] : 0,
                };
                salesByDate.push(arrData);
                // console.log(arrData);
              }
            }
            firstRecord = false;
          }
        });
      });
      const rupiah = this.hitungBiaya(minKonsumsi, custPemakaian)["total"];
      customerSales.push({
        customerId: cs.customerId,
        customerName: cs.customerName ? cs.customerName : "blank",
        m3: custPemakaian,
        rupiah: rupiah,
        startMeter: startMeter,
        finishMeter: finishMeter,
      });
      totRp += rupiah;
    });
    let tot15Pemakaian = 0;
    let tot15PemakaianRp = 0;
    customerSales = _.orderBy(customerSales, ["m3"], ["desc"]);
    customerSales.forEach((o, i) => {
      if (i < 15) {
        tot15Pemakaian += o.m3;
        tot15PemakaianRp += o.rupiah;
      } else {
        return;
      }
    });
    // console.table(salesByDate.filter(v => v.month === 202204 && v.m3Sepuluh !== 0 && v.pemakaian !== 0));
    return {
      details: customerSales,
      salesByDate: salesByDate,
      tot15Pemakaian: tot15Pemakaian,
      tot15PemakaianRp: tot15PemakaianRp,
      grandTotal: totPj,
      totalRupiah: totRp,
    };
  }

  getSingleNrwReport(id: string): Observable<any> {
    return this._afs
      .collection<any>(this.dbNrwReport)
      .doc(id)
      .valueChanges({ idField: "id" });
  }

  saveNrwReport(id, data): void {
    this._afs.collection("nrwReport").doc(id).set(data, { merge: true });
  }

  saveViewMeter(data): void {
    const batch = this._afs.firestore.batch();
    //batch limit dalam sekali eksekusi sebanyak 500 data

    data.forEach((dt) => {
      const nycRef = this._afs.collection("viewMeter").doc(dt.id).ref;
      batch.set(nycRef, dt, { merge: true });
    });
    batch.commit();
  }

  saveViewPemakaian(data): void {
    const batch = this._afs.firestore.batch();
    //batch limit dalam sekali eksekusi sebanyak 500 data

    data.forEach((dt) => {
      const nycRef = this._afs
        .collection("viewPemakaian")
        .doc(dt.customerId + "-" + dt.tanggal).ref;
      batch.set(nycRef, dt, { merge: true });
    });
    batch.commit();
  }

  isEnableEditPreviousMonth(forDate): any {
    // YYYYMM
    const maxNumber = 6;
    const selisihBulan = moment(moment().format("YYYYMM")).diff(
      moment(forDate, "YYYYMM"),
      "months"
    );
    if (selisihBulan <= maxNumber) {
      return { enabled: true, selisih: selisihBulan, maxNumber: maxNumber };
    }
    return { enabled: false, selisih: selisihBulan, maxNumber: maxNumber };
  }

  getPrice(): any {
    return this._afs
      .collection("price")
      .doc("billing-price-tL18jcyee38dhHGduiwer==")
      .valueChanges({ idField: "id" });
  }

  hitungBiaya(minKonsumsi, pemakaian, realPmkCust = false): any {
    let sepuluh = 0;
    let tengah = 0;
    let duapuluh = 0;
    let m3Sepuluh = 0;
    let m3Tengah = 0;
    let m3Duapuluh = 0;
    let m3DuapuluhReal = 0;
    let m3TengahReal = 0;
    let m3SepuluhReal = 0;
    const realPemakaian = Number(pemakaian);
    const price = {
      lessThanEqualThanTen: 9500,
      betweenTenAndTwenty: 12000,
      moreThanTwenty: 13200,
    };

    m3SepuluhReal = Math.min(realPemakaian, 10);
    m3TengahReal = Math.min(Math.max(realPemakaian - 10, 0), 10);
    m3DuapuluhReal = Math.max(realPemakaian - 20, 0);

    if (pemakaian <= minKonsumsi) pemakaian = minKonsumsi;

    m3Sepuluh = Math.min(pemakaian, 10);
    m3Tengah = Math.min(Math.max(pemakaian - 10, 0), 10);
    m3Duapuluh = Math.max(pemakaian - 20, 0);

    sepuluh = 10 * price.lessThanEqualThanTen;
    tengah = m3Tengah > 0 ? 10 * price.betweenTenAndTwenty : 0;
    duapuluh = m3Duapuluh * price.moreThanTwenty;

    const total = sepuluh + tengah + duapuluh;
    const m3Total = m3Sepuluh + m3Tengah + m3Duapuluh;

    return {
      sepuluh: sepuluh,
      tengah: tengah,
      duapuluh: duapuluh,
      total: total > 5000000 ? total + 10000 : total,
      m3Sepuluh: m3SepuluhReal,
      m3Tengah: m3TengahReal,
      m3Duapuluh: m3DuapuluhReal,
      m3Total: m3Total,
    };
  }

  /**
   * @param minKonsumsi minamal konsumsi
   * @param pemakaian pmkaian
   * @param realPmkCust hitung hanya berdasarkan pemakaian saja, tanpa melibatkan minKonsumsi
   * @returns array
   */
  hitungBiaya2(minKonsumsi, pemakaian, realPmkCust = false): any {
    /**
     * hitungan ini masih ada problem. dimana jika ada pemakaian yg belum mencapai minKonsumsi akan terhitung minKonsumsi (bukan pemakaian).
     * sehingga pemkaian tidak sama dengan pemakaian yg ada di invoice kab serang.
     * di inv kab serang jika di breakdown tiap rang >10,>10 dan <20 serta >20. maka hasilnya pemakaian + minKonsumsi yg belum tercapai
     */

    let sepuluh = 0;
    let tengah = 0;
    let duapuluh = 0;
    let m3Sepuluh = 0;
    let m3Tengah = 0;
    let m3Duapuluh = 0;
    //dibawah 10
    //=IF(I11<10;IF(I11<E11;IF(E11<10;E11*J$8;10*J$8);I11*J$8);10*J$8)
    if (pemakaian <= 10) {
      if (pemakaian < minKonsumsi && !realPmkCust) {
        if (minKonsumsi < 10) {
          sepuluh = minKonsumsi * hargaSepuluh;
          m3Sepuluh = minKonsumsi;
        } else {
          sepuluh = 10 * hargaSepuluh;
          m3Sepuluh = 10;
        }
      } else {
        sepuluh = pemakaian * hargaSepuluh;
        m3Sepuluh = pemakaian;
      }
    } else {
      sepuluh = 10 * hargaSepuluh;
      m3Sepuluh = 10;
    }

    //antara 10 & 20
    //=IF(10<I11<20;IF(I11<E11;IF(E11<20;(E11-10)*K$8;(I11-10)*K$8);(I11-10)*K$8);10*K$8)
    if (10 < pemakaian && pemakaian < 20) {
      if (pemakaian < minKonsumsi && !realPmkCust) {
        if (minKonsumsi < 20) {
          tengah = (minKonsumsi - 10) * hargaTengah;
          m3Tengah = minKonsumsi - 10;
        } else {
          /* tengah = (pemakaian-10)*hargaTengah;
                    m3Tengah = pemakaian-10; */
          tengah = 10 * hargaTengah;
          m3Tengah = 10;
        }
      } else {
        tengah = (pemakaian - 10) * hargaTengah;
        m3Tengah = pemakaian - 10;
      }
    } else if (pemakaian <= 10 && realPmkCust) {
      tengah = 0;
      m3Tengah = 0;
    } else {
      if (pemakaian < 20 && minKonsumsi === 0) {
        //hilangin 0 jika tidak di pakai dan min konsumsi 0. ini salah dari excelnya
      } else {
        tengah = 10 * hargaTengah;
        m3Tengah = 10;
      }
    }

    //diatas 20
    //=IF(I11<=20;IF(E11<=20;0;(E11-20)*$L$8);IF(E11<=I11;(I11-20)*$L$8;(E11-20)*$L$8))
    if (pemakaian <= 20) {
      if (minKonsumsi <= 20 && !realPmkCust) {
        duapuluh = 0;
        m3Duapuluh = 0;
      } else {
        duapuluh = (minKonsumsi - 20) * hargaDuapuluh;
        m3Duapuluh = minKonsumsi - 20;
      }
    } else {
      if (minKonsumsi <= pemakaian) {
        duapuluh = (pemakaian - 20) * hargaDuapuluh;
        m3Duapuluh = pemakaian - 20;
      } else {
        duapuluh = (minKonsumsi - 20) * hargaDuapuluh;
        m3Duapuluh = minKonsumsi - 20;
      }
    }
    //filter lagi
    if (pemakaian === 0 && minKonsumsi === 0) {
      m3Sepuluh = sepuluh = 0;
    }

    const total = sepuluh + tengah + duapuluh;
    const m3Total = m3Sepuluh + m3Tengah + m3Duapuluh;

    return {
      sepuluh: sepuluh,
      tengah: tengah,
      duapuluh: duapuluh,
      total: total,
      m3Sepuluh: m3Sepuluh,
      m3Tengah: m3Tengah,
      m3Duapuluh: m3Duapuluh,
      m3Total: m3Total,
    };
  }
  getMultiple(): Observable<any[]> {
    return this._afs
      .collection<any>(this.dbTaskReports, (ref) =>
        ref
          .where("pelaksana", "==", "operator")
          .where("location", "==", "wtp 3")
          .where("forDate", ">=", 2021110101)
          .where("forDate", "<=", 2021113123)
          .limit(500)
      )
      .valueChanges({ idField: "id" });
  }
  hapusMultiple(): void {
    const batch = this._afs.firestore.batch();

    this.getMultiple()
      .pipe(take(1))
      .subscribe((next) => {
        // console.log(next);
        next.forEach((it) => {
          const nycRef = this._afs
            .collection(this.dbTaskReports)
            .doc(it.id).ref;
          batch.delete(nycRef);
        });
        batch.commit();
      });
  }

  generatePassword(): string {
    const possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-=[]{};:',./<>?";

    let text = "";
    for (let i = 0; i < 20; i++) {
      const n = CryptoJS.lib.WordArray.random(32);
      const s = CryptoJS.enc.Base64.stringify(n);
      text += possible.charAt(s.charCodeAt(i) % possible.length);
    }

    const uniqueChars = new Set(text);
    if (uniqueChars.size !== 20) {
      return this.generatePassword();
    }

    return text;
  }
}
