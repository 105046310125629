export const logo = "assets/images/logo/logo.svg";

export const namaUrutan = [
  "Pertama",
  "Kedua",
  "Ketiga",
  "Keempat",
  "Kelima",
  "Keenam",
  "Ketujuh",
  "Kedelapan",
  "Kesembilan",
];
export const kebutuhanAirs = [
  "< 1 atau <380",
  "1 - 2 atau 380 - 1500",
  "2 - 5 atau 1500 - 3300",
  "5 - 7 atau 3300 - 4900",
  "7 - 22 atau 4900 - 15500",
  "22 - 36 atau 15500 - 25900",
  "36 - 58 atau 25900 - 41400",
  ">58 atau 41400",
];
export const namaJam = [
  8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 1, 2, 3, 4,
  5, 6, 7,
];
export const arJamCmb = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];
export const lokasiAmbil = [
  "air baku",
  "wtp 1",
  "wtp 2",
  "wtp 3",
  "sedimen",
  "distribusi",
  "pelanggan",
];
export const cryptoToken = "NJkJI8PPJDKESGRAFIHOUSE";
export const roleSuperAdminCode = "509302KSUK09PER3229";
export const durasiAkanJatuhTempo = 7;
export const pressureDistribusiIds = [
  "R12220302104110",
  "R16220302103150",
  "R17220302102538",
  "R10220301182253",
  "R13220302103755",
  "R11220302102328",
  "R18220302103959",
  "R16220302103315",
  "R18220302102146",
  "R15220302103028",
  "R19220302102914",
  "R17220302102701",
  "R21220302103603",
  "R19220302103705",
];
export const typeCustomer: any = [
  { key: "industri", value: "Industri" },
  { key: "domestik", value: "Domestik" },
  { key: "industri dan domestik", value: "Industri Dan Domestik" },
];
export const arCategoryNonRutin: any = [
  { key: "umum", value: "Umum" },
  { key: "reset", value: "Reset Meter" },
];
export const statusNonRutin: any = [
  { key: 0, value: "Baru" },
  { key: 1, value: "Dikerjakan" },
  { key: 2, value: "Selesai" },
  { key: -1, value: "Pending" },
];
export const arTypeComment: any = [
  { key: "-", value: "Pilih Status" },
  { key: "Sebelum", value: "Sebelum" },
  { key: "Pengerjaan", value: "Pengerjaan" },
  { key: "Selesai", value: "Selesai" },
];
export const statusChangeSchedule: any = [
  { key: 0, value: "Menunggu" },
  { key: -1, value: "Ditolak" },
  { key: 1, value: "Diterima" },
];
export const arLocations: any = [
  { key: "intake", value: "Intake" },
  { key: "wtp 1", value: "WTP 1" },
  { key: "wtp 2", value: "WTP 2" },
  { key: "wtp 3", value: "WTP 3" },
  { key: "distribusi", value: "Jaringan Distribusi" },
  { key: "pelanggan", value: "Pelanggan" },
  { key: "booster", value: "Booster Pump" },
  { key: "lain", value: "Lain Lain" },
];
export const statusKepemilikanBangunan: any = ["Milik Sendiri", "Sewa"];
export const mapAPIKey = "AIzaSyCiBaJTvxNCJ_fHK8nAA8EZSwiyWrtkFV0";

export const beginYear = 2022;
export const hargaSepuluh = 9500;
export const hargaTengah = 12000;
export const hargaDuapuluh = 13200;

export const biayaAirBaku = 100.98;
export const biayaPac = 3100;
export const biayaKap = 3850;
export const biayaPol = 52000;

export const wtp1BakuId = "PoieD3yxn4avq9SrEp18";
export const wtp1Cikande = "g7cSe37ROXCVtVyopNyJ"; //produksi wtp1
export const wtp1R2r1ke3 = "R13220424053621";

export const wtp2BakuId = "R18220424053154";
export const wtp2Produksi = "R18220424061815";

export const wtp3BakuId = "n2adtBK2C8hoGio0RJnu";
export const wtp3Industri = "TJFeQKrXcHNtSoTcahfg";
export const wtp3Carenang = "JOFQowzTotL69WsY12W5";
export const wtp3Cijeruk = "yUe74oUfCtWXiK4HU5BK";
export const wtp3R2r3ke1 = "R13220424053843";

export const intakePompa = "25BSRdRfOpVYB28mIvTo";
export const wtp3PomTransfer = "lBPqdEOl7gzGheB7EDq1";
export const wtp1PomDist = "IdGprF3Hg8uvIOLNLhhq";
export const wtp3PomDist = "tVi1IjNLC0Gsdqk80lQd";

export const wtp1TangkiPac = "dMJjoMisA53RcpsfKylw";
export const wtp1TangkiKap = "Qx2j9Qp9xCT2D1aIHwKN";
export const wtp3TangkiPac = "34oC4Q1JQZJWHm05be2U";
export const wtp3TangkiKap = "hksRBGUpKzPIzF9o4lXC";
export const wtp3TangkiPol = "2vgKSP5ocyYEqwrflWoV";

export const wtp1DayaKwh = "bpnPtGCECqqViaV13eLF";

export const meterPac = "R9220224200722";
export const meterKap = "R13220224200559";

export const keyPac = "16345231334890-" + wtp1TangkiPac;
export const keyPacPmk = "16383988537861-" + wtp1TangkiPac;
export const keyKap = "16383989506400-" + wtp1TangkiKap;
export const keyKapPmk = "16383989506401-" + wtp1TangkiKap;

export const keyPacA = "16340293635090-" + wtp3TangkiPac;
export const keyPacAPmk = "16383984790641-" + wtp3TangkiPac;
export const keyPacB = "16383984790642-" + wtp3TangkiPac;
export const keyPacBPmk = "16383984790643-" + wtp3TangkiPac;

export const keyKapA = "16383986334010-" + wtp3TangkiKap;
export const keyKapAPmk = "16383986334011-" + wtp3TangkiKap;
export const keyKapB = "16383986334012-" + wtp3TangkiKap;
export const keyKapBPmk = "16383986334013-" + wtp3TangkiKap;

export const keyPolA = "16383987132620-" + wtp3TangkiPol;
export const keyPolAPmk = "16383987132621-" + wtp3TangkiPol;
export const keyPolB = "16383987132622-" + wtp3TangkiPol;
export const keyPolBPmk = "16383987132623-" + wtp3TangkiPol;

export const keyMeterPac = "16457080420980-" + meterPac;
export const keyMeterPacPmk = "16457080420981-" + meterPac;
export const keyMeterKap = "16457079597290-" + meterKap;
export const keyMeterKapPmk = "16457079597291-" + meterKap;

export const zonaPelanggan = [
  { key: 1, value: "Zona 1 Andi" },
  { key: 2, value: "Zona 2 Marsidin" },
  { key: 3, value: "Zona 3 Subana" },
  { key: 4, value: "Zona 4 Amir" },
];
export const namaHari = [
  {
    abbr: "sen",
    key: 1,
    value: "Senin",
  },
  {
    abbr: "sls",
    key: 2,
    value: "Selasa",
  },
  {
    abbr: "rab",
    key: 3,
    value: "Rabu",
  },
  {
    abbr: "kam",
    key: 4,
    value: "Kamis",
  },
  {
    abbr: "jum",
    key: 5,
    value: "Jumat",
  },
  {
    abbr: "sab",
    key: 6,
    value: "Sabtu",
  },
  {
    abbr: "min",
    key: 7,
    value: "Minggu",
  },
];
export const statusCustomer = [
  {
    id: 0,
    name: "Pengajuan",
  },
  {
    id: 1,
    name: "Aktif",
  },
  {
    id: -1,
    name: "Non Aktif",
  },
];

export const namaBulan = [
  {
    key: 1,
    abbr: "jan",
    value: "Januari",
  },
  {
    key: 2,
    abbr: "feb",
    value: "Februari",
  },
  {
    key: 3,
    abbr: "mar",
    value: "Maret",
  },
  {
    key: 4,
    abbr: "apr",
    value: "April",
  },
  {
    key: 5,
    abbr: "mei",
    value: "Mei",
  },
  {
    key: 6,
    abbr: "jun",
    value: "Juni",
  },
  {
    key: 7,
    abbr: "jul",
    value: "Juli",
  },
  {
    key: 8,
    abbr: "agu",
    value: "Agustus",
  },
  {
    key: 9,
    abbr: "sep",
    value: "September",
  },
  {
    key: 10,
    abbr: "okt",
    value: "Oktober",
  },
  {
    key: 11,
    abbr: "nov",
    value: "November",
  },
  {
    key: 12,
    abbr: "des",
    value: "Desember",
  },
];

export const jabatan = [
  {
    id: "1",
    name: "Direktur Utama",
    urutan: "1",
  },
  {
    id: "2",
    name: "Direktur Keuangan",
    urutan: "2",
  },
  {
    id: "3",
    name: "Direktur Teknik / MD",
    urutan: "3",
  },
  {
    id: "4",
    name: "Manajer. Keu, Akun. & pajak",
    urutan: "4",
  },
  {
    id: "5",
    name: "Manajer Komersial",
    urutan: "5",
  },
  {
    id: "6",
    name: "Manajer Teknik & Operasi",
    urutan: "6",
  },
  {
    id: "7",
    name: "KaBag Keuangan",
    urutan: "7",
  },
  {
    id: "8",
    name: "KaBag Akunting & Pajak",
    urutan: "8",
  },
  {
    id: "9",
    name: "KaBag Marketing",
    urutan: "9",
  },
  {
    id: "10",
    name: "KaBag Sales & Distribusi",
    urutan: "10",
  },
  {
    id: "11",
    name: "Technical Sales",
    urutan: "11",
  },
  {
    id: "12",
    name: "Finance Controller",
    urutan: "12",
  },
  {
    id: "13",
    name: "KaBag Pengadaan",
    urutan: "13",
  },
  {
    id: "14",
    name: "KaBag Personalia & Umum",
    urutan: "14",
  },
  {
    id: "15",
    name: "KaBag Kepatuhan/QHSE",
    urutan: "15",
  },
  {
    id: "16",
    name: "KaBag Op. Perenc, digitalization",
    urutan: "16",
  },
  {
    id: "17",
    name: "KaBag Pemeliharaan  & Lab",
    urutan: "17",
  },
  {
    id: "18",
    name: "Kasie Gudang & Aset",
    urutan: "18",
  },
  {
    id: "19",
    name: "Kasie Pem & Distribusi",
    urutan: "19",
  },
  {
    id: "20",
    name: "Kasie Personalia & Umum",
    urutan: "20",
  },
  {
    id: "21",
    name: "Kasie Produksi",
    urutan: "21",
  },
  {
    id: "22",
    name: "Kasie Perencanaan",
    urutan: "22",
  },
  {
    id: "23",
    name: "Kasie Pemeliharaan",
    urutan: "23",
  },
  {
    id: "24",
    name: "Kepala Lab",
    urutan: "24",
  },
  {
    id: "25",
    name: "Staf Keuangan",
    urutan: "25",
  },
  {
    id: "26",
    name: "Staf Akunting & Pajak",
    urutan: "26",
  },
  {
    id: "27",
    name: "Staf Hubungan Pelanggan",
    urutan: "27",
  },
  {
    id: "28",
    name: "Staf Umum",
    urutan: "28",
  },
  {
    id: "29",
    name: "Staf QHSE",
    urutan: "29",
  },
  {
    id: "30",
    name: "Teknisi",
    urutan: "30",
  },
  {
    id: "31",
    name: "Analis",
    urutan: "31",
  },
  {
    id: "32",
    name: "Operator Intake",
    urutan: "32",
  },
  {
    id: "33",
    name: "Operator Prod & Lumpur",
    urutan: "33",
  },
  {
    id: "34",
    name: "Operator Distribusi",
    urutan: "34",
  },
];
export const deptHirarcyOperator = [
  {
    parent: 16, // kabag op perencanaan
    childs: [21, 22, 31, 32, 33],
  },
  {
    parent: 17, // kabag teknisi
    childs: [30, 23, 24],
  },
  {
    parent: 10, // kabag sales / distribusi
    childs: [34],
  },
];
export const romawi = [
  "0",
  "I",
  "II",
  "III",
  "IV",
  "V",
  "VI",
  "VII",
  "VIII",
  "IX",
  "X",
  "XI",
  "XII",
];
export const managerIds = [6, 4, 5];
export const deptHirarcy = [
  // hanya dari level manajer ke bawah
  {
    parent: 6, // manajer teknik
    childs: [16, 21, 22, 32, 33, 17, 30, 31, 23, 24],
  },
  {
    parent: 16, // kabag op perencanaan
    childs: [21, 22, 31, 32, 33],
  },
  {
    parent: 21, // kasie op produksi
    childs: [32, 33],
  },
  {
    parent: 17, // kabag teknisi
    childs: [30, 23, 24],
  },
  {
    parent: 15, // kabag qhse
    childs: [29],
  },
  {
    parent: 14, // kabag personalia & umum
    childs: [20, 28],
  },
  {
    parent: 20, // kasie personalia & umum
    childs: [28],
  }, //-------------------- atas teknik ----- bawah keuangan ---------

  {
    parent: 4, // manajer keuangan
    childs: [7, 25, 8, 26, 18],
  },
  {
    parent: 7, // kabag keuangan
    childs: [25],
  },
  {
    parent: 8, // kabag akunting pajak
    childs: [26],
  },
  {
    parent: 5, // manajer komersial
    childs: [10, 34, 9, 11, 27],
  },
  {
    parent: 10, // kabag sales / distribusi
    childs: [34],
  },
  {
    parent: 11, // technical sales
    childs: [27],
  },
];
